import { logDev } from "@/hooks/useEnvironment";
import { pasRequest } from "@/hooks/usePas";

export function searchStkcode(stkcode, stockType) {
  if (stkcode === null || stockType === null) return;

  try {
    return pasRequest("stockcode-lookup", "search-stkcode", {
      stkcode,
      stockType,
    });
  } catch (e) {
    logDev(e);
    return;
  }
}

export function searchDescription(data, stockType) {
  if (data === null) return;

  logDev(data);

  try {
    return pasRequest("stockcode-lookup", "search-description", {
      description: data,
      stockType,
    });
  } catch (e) {
    logDev(e);
    return;
  }
}

export function searchSuppliersStkcode(data, stockType) {
  if (data === null) return;

  logDev(data);

  try {
    return pasRequest("stockcode-lookup", "search-suppliers-stkcode", {
      suppliersStkcode: data,
      stockType,
    });
  } catch (e) {
    logDev(e);
    return;
  }
}

export function searchOldStkcode(data, stockType) {
  if (data === null) return;

  logDev(data);

  try {
    return pasRequest("stockcode-lookup", "search-old-stkcode", {
      oldStkcode: data,
      stockType,
    });
  } catch (e) {
    logDev(e);
    return;
  }
}

export function searchCategory(data, stockType) {
  if (data === null) return;

  logDev(data);

  try {
    return pasRequest("stockcode-lookup", "search-category", {
      category: data,
      stockType,
    });
  } catch (e) {
    logDev(e);
    return;
  }
}

export function searchManufacturersCode(data) {
  if (data === null) return;

  logDev(data);

  try {
    return pasRequest("stockcode-lookup", "search-manufacturers-code", {
      manufacturerCode: data,
      stockType,
    });
  } catch (e) {
    logDev(e);
    return;
  }
}

export function searchDepartment(data, stockType) {
  if (data === null) return;

  logDev(data);

  try {
    return pasRequest("stockcode-lookup", "search-department", {
      department: data,
      stockType,
    });
  } catch (e) {
    logDev(e);
    return;
  }
}

export function searchPrimaryBarcode(data, stockType) {
  if (data === null) return;

  logDev(data);

  try {
    return pasRequest("stockcode-lookup", "search-primary-barcode", {
      primaryBarcode: data,
      stockType,
    });
  } catch (e) {
    logDev(e);
    return;
  }
}

export function searchSupplier(data, stockType) {
  if (data === null) return;

  logDev(data);

  try {
    return pasRequest("stockcode-lookup", "search-supplier", {
      supplier: data,
      stockType,
    });
  } catch (e) {
    logDev(e);
    return [];
  }
}

export const allSearchFunctions = {
  searchStkcode,
  searchDescription,
  searchSuppliersStkcode,
  searchOldStkcode,
  searchCategory,
  searchManufacturersCode,
  searchDepartment,
  searchPrimaryBarcode,
  searchSupplier,
};
