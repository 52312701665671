import {
  sessionData,
  resetSessionData,
  resetSessionShop, resetSessionWarehouse,
} from "@/store/sessionStore.js";
import { pasRequest } from "@/hooks/usePas.js";
import { showConfirm } from "@/hooks/useModals.js";
import { saveAllLocalStorage } from "@/hooks/useLocalStorage.js";
import { hoIniSettings } from "@/store/headOfficeStore.js";

export function isLoggedIn() {
  return !!sessionData.sessionGuid;
}

/**
 * @desc Tries to end the session with head office and return to the sign in screen
 *       if this fails for any reason we prompt the user that we couldn't log them
 *       out and ask if they want to try again (to make sure the license frees up
 *       unless the forcelogout flag is true
 * @param forceLogout {boolean}
 * @return {Promise<boolean>}
 */
export async function personnelSignOut(forceLogout = false) {
  //Can't log out, we already log the session GUID, just say we did as there's not much we can do
  if (sessionData.sessionGuid === null) {
    await clearLoginSession();
    return true;
  }

  try {
    await pasRequest("core-api", "personnel-sign-out", {});
  } catch (e) {
    try {
      //Couldn't get the OK from WinRetail for some reason (Most likely network) give option to retry or potentially leave the session open at HO
      await showConfirm({
        title: "Sign out failed",
        content:
          "Unable to get confirmation of your sign out from head office, you can force sign out this device but your session will still use a license at head office until is expires.",
        yesLabel: "Force logoff",
        noLabel: "Stay signed in",
      });
    } catch (e) {
      return false;
    }
  }

  await clearLoginSession();
  return true;
}

export async function clearLoginSession() {
  // Reset the data to defaults
  resetSessionData();
  resetSessionShop();
  resetSessionWarehouse();

  await saveAllLocalStorage();
}

export function hasPermission(permission) {

  // No permission if not logged in
  if (isNaN(sessionData.personnel.number) || sessionData.personnel.number <= 0) {
    console.log("No user is logged in when checking permissions");
    return false;
  }

  // No permission if user level is invalid
  if (isNaN(sessionData.personnel.level)) {
    console.log(`Personnel number ${sessionData.personnel.number} does not have a valid permission level`);
    return false;
  }

  // If we can find the specified entry
  if (Object.hasOwn(hoIniSettings.permissions, permission)) {

    let level = hoIniSettings.permissions[permission];

    // If it's a number we'll compare to the users HO level
    if (level && !isNaN(parseInt(level)))
      return sessionData.personnel.level >= level;

    // If it's a string we'll check for a matching group name
    if (level && sessionData.personnel.groups)
      // The string may be | separated, split it into an array for testing
      return level.split("|").some(value => sessionData.personnel.groups.includes(value));

    console.log(`Permission entry ${permission} did not have a valid value (${level})`);
    return false;
  }

  console.log(`Permission entry ${permission} could not be found`)
  return false;
}

export function isWarehouseUser() {

  // No permission if not logged in
  if (isNaN(sessionData.personnel.number) || sessionData.personnel.number <= 0) {
    console.log("No user is logged in when checking for Warehouse user");
    return false;
  }

  // If both warehouse and shop number are 0, the user can access everything
  if (sessionData.personnel.warehouseNumber === 0 && sessionData.personnel.shopNumber === 0) return true;

  return sessionData.personnel.warehouseNumber > 0;
}

export function isStoreUser() {

  // No permission if not logged in
  if (isNaN(sessionData.personnel.number) || sessionData.personnel.number <= 0) {
    console.log("No user is logged in when checking for Store user");
    return false;
  }

  // If both warehouse and shop number are 0, the user can access everything
  if (sessionData.personnel.warehouseNumber === 0 && sessionData.personnel.shopNumber === 0) return true;

  return sessionData.personnel.shopNumber > 0;
}
