<template>
  <div class="row margin-bottom-tiny">
    <div
      class="input-label margin-top-tiny"
      v-bind:class="props.longerLabel ? 'col-4' : 'col-3'"
    >
      {{ label }}
    </div>

    <template v-if="shortField">
      <div class="padding-right padding-left-none col-4">
        <input
          v-on:keydown.enter="emit('key-enter')"
          type="number"
          v-bind:id="id"
          v-model="value"
          :readonly="readonly"
          :disabled="readonly"
          :class="readonly === true ? 'text-unselectable' : ''"
        />
      </div>

      <div
        class="padding-right padding-left-none"
        v-bind:class="props.longerLabel ? 'col-4' : 'col-5'"
      >
        <slot />
      </div>
    </template>

    <div
      v-else
      class="padding-right padding-left-none"
      v-bind:class="props.longerLabel ? 'col-8' : 'col-9'"
    >
      <input
        v-on:keydown.enter="emit('key-enter')"
        type="number"
        v-bind:id="id"
        v-model="value"
        :readonly="readonly"
        :disabled="readonly"
        :class="readonly === true ? 'text-unselectable' : ''"
      />
    </div>
  </div>
</template>

<script setup>
const value = defineModel();

const props = defineProps({
  label: {
    type: String,
  },
  longerLabel: {
    type: Boolean,
    default: false,
  },
  allowDecimal: {
    type: Boolean,
    default: false,
  },
  unitLabel: {
    type: String,
    default: "",
  },
  minValue: {
    type: Number,
    default: 0,
  },
  maxValue: {
    type: Number,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: "",
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  shortField: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: "num-input",
  },
});

const emit = defineEmits(["key-enter"]);
</script>

<style scoped></style>
