export function isDev() {
  return import.meta.env.MODE === "development";
}

export function logDev(data) {
  if (isDev() === true) {
    console.dir("------- DEV ONLY - Log Start -------");
    console.dir(data);
    console.dir("------- DEV ONLY - Log End -------");
  }
}
