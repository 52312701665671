<template>
  <div
    class="hide-scroll-bar"
    v-bind:class="[
      position.toLowerCase() === 'top'
        ? 'modal-container-top'
        : 'modal-container',
    ]"
  >
    <slot> </slot>
  </div>
</template>

<script setup>
import { onBeforeMount, onUnmounted } from "vue";
import uiStore from "@/store/uiStore.js";

const props = defineProps({
  position: { type: String, default: "bottom" },
  useMask: { type: Boolean, default: true },
});

onBeforeMount(function () {
  if (props.useMask === true) {
    uiStore.mask.modalCounter++;
  }
});
onUnmounted(function () {
  if (props.useMask === true) {
    uiStore.mask.modalCounter--;
  }
});
</script>

<style scoped>
.modal-container {
  border-top: 5px solid var(--pda-primary-color);
  border-radius: 3px;
  height: fit-content;
  max-height: calc(100dvh - 20px);
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: 0;
  z-index: 10000;
  padding: 10px;
  overflow-y: scroll;
}

.modal-container-top {
  border-bottom: 5px solid var(--pda-primary-color);
  border-radius: 3px;
  min-height: 75px;
  height: fit-content;
  max-height: 30dvh;
  width: 100%;
  background-color: var(--pda-primary-color);
  color: var(--pda-secondary-color);
  position: absolute;
  top: 0;
  z-index: 999999;
  padding: 10px;
  overflow-y: scroll;
}
</style>
