export const SharedRoutes = [
  // Goods In
  {
    path: "/goodsIn",
    name: "Goods In",
    component: () =>
      import("../views/shared/goodsIn/DeliverySelection.vue"),
  },
  {
    path: "/goodsIn",
    name: "Goods In Main",
    component: () => import("../views/shared/goodsIn/Main.vue"),
  },
  {
    path: "/goodsIn/purchaseOrderList",
    name: "Goods In Purchase Order List",
    component: () => import("../views/shared/goodsIn/PurchaseOrderList.vue"),
  },
  {
    path: "/goodsIn/createDelivery",
    name: "Goods In Create Delivery",
    component: () => import("../views/shared/goodsIn/CreateDelivery.vue"),
  },
];
