import { sessionData } from "@/store/sessionStore.js";
import { deviceData } from "@/store/deviceStore.js";
import { headOfficeCache, hoIniSettings } from "@/store/headOfficeStore.js";
import { updatePasEndpoint } from "@/hooks/usePas.js";

export function localStorageSaveObject(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export async function saveAllLocalStorage() {
  localStorageSaveObject("cfPdaSessionData", sessionData);
  localStorageSaveObject("cfPdaDeviceData", deviceData);
  localStorageSaveObject("hoConfigCache", hoIniSettings);
  localStorageSaveObject("hoDataCache", headOfficeCache);

  if (navigator.storage && navigator.storage.persist) {
    const result = await navigator.storage.persist();
    console.log(`Data persisted: ${result}`);
  }
}

export function initialiseStorage() {
  //Session data (Session GUID, selected stock take/purchase order etc

  let browserSessionData = localStorage.getItem("cfPdaSessionData");

  if (browserSessionData !== null)
    Object.assign(sessionData, JSON.parse(browserSessionData));

  //Device Data, information such as device label (pda01 etc) preferences etc

  let browserDeviceData = localStorage.getItem("cfPdaDeviceData");

  if (browserDeviceData !== null)
    Object.assign(deviceData, JSON.parse(browserDeviceData));

  updatePasEndpoint();

  //HO Settings, when we successfully log in we update these, but in case there are any connection issues, keep a cache.
  let browserHoConfigCache = localStorage.getItem("hoConfigCache");

  if (browserHoConfigCache !== null) {
    hoIniSettings.load(JSON.parse(browserHoConfigCache));
    console.log("Loaded HO Settings from local storage");
  }

  let browserHoDataCache = localStorage.getItem("hoDataCache");

  if (browserHoDataCache !== null) {
    //Convert from JSON string to Object
    browserHoDataCache = JSON.parse(browserHoDataCache);

    //Shops
    headOfficeCache.shopList = browserHoDataCache.shopList;
    headOfficeCache.supplierList = browserHoDataCache.supplierList;
  }
}
