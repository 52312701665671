import { reactive } from "vue";

const state = reactive({
  mask: {
    enabled: false,
    modalCounter: 0,
  },
  settingsPage: {
    show: "",
  },
  messages: [],
  loader: {
    enabled: false,
    enabledCount: 0,
    label: "Please Wait...",
    description: "",
  },
  shopSelect: {
    visible: false,
    onSelectCallBack: null,
    onErrorCallBack: null,
  },
  stkcodeLookup: {
    visible: false,
    onSuccess: null,
    onFail: null,
  },
  supplierLookup: {
    visible: false,
    onSuccess: null,
    onFail: null,
    localCurrencySuppliersOnly: false,
  },
  purchaseOrderingStkcodeLookup: {
    visible: false,
    onSuccess: null,
    onFail: null,
    poNumber: null,
  },
  goodsInStkcodeLookup: {
    visible: false,
    onSuccess: null,
    onFail: null,
    supplierNumber: null,
    suppliersDeliveryReference: null,
    poNumber: null,
  },
  multipleStkcodeSelect: {
    visible: false,
    onSuccess: null,
    onFail: null,
    stkmstList: [],
  },
  sideBar: {
    visible: false,
    items: [
      { type: "header", label: "Device" },
      {
        type: "link",
        label: "Sign Out",
        icon: "door-closed-fill",
      },
      {
        type: "link",
        label: "Force Full Screen",
        icon: "arrows-fullscreen",
      },
      {
        type: "link",
        label: "Device Details",
        icon: "info-circle-fill",
      },
      {
        type: "link",
        label: "Error Logs",
        icon: "exclamation-diamond-fill",
      },

      {
        type: "header",
        label: "Settings",
      },
      {
        type: "link",
        label: "WinRetail",
        icon: "pc",
      },
      {
        type: "link",
        label: "Camera",
        icon: "camera-fill",
      },
    ],
  },
  scannerModal: {
    visible: false,
  },
  purchaseOrderDetails: {
    savedUi: null,
  },
  stockEnquiry: {
    stkcode: null,
  },
});

export default state;
