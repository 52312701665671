<template>
  <com-modal-body>
    <h1
      class="text-center text-unselectable"
      v-bind:class="{ 'text-danger': ModalStore.alert.danger }"
    >
      {{ ModalStore.alert.title }}
    </h1>
    <hr />
    <h4
      class="text-center text-unselectable"
      v-html="ModalStore.alert.content"
      style="min-height: 150px"
    ></h4>
    <button
      v-on:click="onClickClose"
      class="btn-default btn-primary height-med width-max"
    >
      Close
    </button>
  </com-modal-body>
</template>

<script setup>
import ComModalBody from "@/components/ui/ComModalBody.vue";
import { ModalStore } from "@/store/modalStore.js";
import { onMounted } from "vue";
import { playSound } from "@/hooks/useAudio.js";

async function onClickClose() {
  ModalStore.alert.closeCallback();
}

onMounted(async () => {
  if (ModalStore.alert.sound !== null) {
    await playSound(ModalStore.alert.sound);
  }
});
</script>

<style scoped>
h1 {
  text-align: center;
  width: 100%;
}

h4 {
  text-align: center;
}
</style>
