import { sessionStatus } from "@/store/deviceStore.js";

const elem = document.documentElement;
const viewport = document.querySelector("meta[name=viewport]");

export function openFullscreen() {
  if (sessionStatus.desktopEnvironment === true) return;

  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
}

export function forceViewport() {
  viewport.setAttribute(
    "content",
    "width=480, height=800, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
  );
  console.log(viewport.getAttribute("content"));
}

export function checkPwa() {
  return ["fullscreen", "standalone", "minimal-ui"].some(
    (displayMode) =>
      window.matchMedia("(display-mode: " + displayMode + ")").matches
  );
}

export function hashData(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    let code = string.charCodeAt(i);
    hash = (hash << 5) - hash + code;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash.toString();
}
