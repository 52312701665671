import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Camera from "simple-vue-camera";

import "./assets/css/vue-transitions.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/css/master.css";
import { useRegisterSW } from "virtual:pwa-register/vue";
import { pwaStatus } from "./store/deviceStore.js";
import { logDev } from "./hooks/useEnvironment.js";
import * as Sentry from "@sentry/vue";

const app = createApp(App)
  .component("camera", Camera)
  .use(router)
  .mount("#app");

const { updateServiceWorker } = useRegisterSW({
  async onNeedRefresh() {
    logDev("update available");
    pwaStatus.updateAvailable = true;
  },
});

Sentry.init({
  app,
  dsn: "https://ccd70fbdf3376571abf63e7e16702f8c@o4508409727811584.ingest.de.sentry.io/4508409729712208",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export async function updatePda() {
  await updateServiceWorker();
}
