<template>
  <com-modal-body>
    <div class="container padding-x-none">
      <div
        class="col-12 small padding-left-none padding-right-half text-unselectable"
        style="text-align: center"
      >
        <h2>Supplier Lookup</h2>
      </div>
      <div
        class="new-table margin-top-none"
        style="
          height: 65dvh;
          max-height: calc(63dvh - 5px);
          max-width: calc(100dvw - 20px);
          overflow-x: scroll !important;
        "
      >
        <table
          v-if="supplierList.length > 0"
          style="width: calc(100%); table-layout: fixed"
        >
          <!--                        TABLE HEADERS-->
          <thead>
          <tr style="">
            <th style="width: 8ch">Number</th>
            <th>Name</th>
          </tr>
          </thead>
          <!--                        TABLE DATA -->
          <tbody>
          <tr
            v-for="supplier in supplierList"
            :key="supplier.number"
            :class="
                ui.selectedSupplier === supplier.number ? 'selected' : ''
              "
            @click="onRowSelected(supplier.number)"
          >
            <td>{{ supplier.number }}</td>
            <td>
              <div class="autoscroll-container">
                <div
                  :class="supplier.name.length > 28 ? 'autoscroll-value' : ''"
                >
                  {{ supplier.name }}
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div v-else>
          <h1 class="text-center" style="padding-top: 120px">
            No records found
          </h1>
          <p class="text-center bold">Consider adjusting your search terms.</p>
        </div>
      </div>

      <div class="row margin-bottom-tiny">
        <com-string-input
          id="searchTerm"
          v-model="ui.searchTerm"
          label="Search:"
          class="margin-top-half"
          @input="ui.selectedSupplier = null"
        />
      </div>

      <div class="row margin-top">
        <div class="col-5">
          <button
            class="btn-default height-med"
            style="width: 100%"
            @click="onClickCancel"
          >
            Cancel
          </button>
        </div>
        <div class="col-5 offset-2">
          <button
            class="btn-default btn-action height-med"
            style="width: 100%"
            :disabled="ui.selectedSupplier === null"
            @click="onClickOk"
          >
            Select
          </button>
        </div>
      </div>

    </div>
  </com-modal-body>
</template>

<script setup>
import { reactive, onMounted, onUnmounted, computed } from "vue";
import { globalEmitter } from "@/hooks/useGlobalEmitter";
import ComModalBody from "@/components/ui/ComModalBody.vue";
import { headOfficeCache, hoIniSettings } from "@/store/headOfficeStore.js";
import ComStringInput from "@/components/inputFields/ComStringInput.vue";
import { setFocus } from "@/hooks/useUtilities.js";

const props = defineProps({
  onSuccess: {
    type: Function,
    required: true,
  },
  onFail: {
    type: Function,
    required: true,
  },
  localCurrencySuppliersOnly: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const uiDefaults = {
  selectedSupplier: null,
  searchTerm: "",
  showModal: false,
};

const ui = reactive(Object.assign({}, uiDefaults));

// Let's create a sorted (optional filtered for local currency only) local copy of the supplier list
const localSupplierList = props.localCurrencySuppliersOnly
                          ? headOfficeCache.supplierList.filter((supplier) =>
                                                                 supplier.currencyCode === hoIniSettings.currency.localCurrencyCode)
                                                        .sort((supplier1, supplier2) => supplier1.name.localeCompare(supplier2.name))
                          : [...headOfficeCache.supplierList]
                                                        .sort((supplier1, supplier2) => supplier1.name.localeCompare(supplier2.name));

const supplierList = computed(() =>  ui.searchTerm > ""
                                            ? localSupplierList.filter((supplier) =>
                                              supplier.number.toString().indexOf(ui.searchTerm) >= 0 ||
                                              supplier.name.toLowerCase().indexOf(ui.searchTerm.toLowerCase()) >= 0)
                                            : [...localSupplierList]);

const onClickCancel = function () {
  props.onFail();
};

const onClickOk = function () {
  if (ui.selectedSupplier > 0) {
    props.onSuccess(ui.selectedSupplier);
  }

  props.onFail();
};

function onRowSelected(supplierNumber) {
  ui.selectedSupplier = supplierNumber;
}

function ignoreScan() {
  console.log("Ignoring barcode scan");
}

onMounted(function () {
  //Don't want the prog underneath reacting to scans
  globalEmitter.on("barcodeScannedLevel2", ignoreScan);

  setFocus("#searchTerm input")
});

onUnmounted(function () {
  //Release the listener
  globalEmitter.off("barcodeScannedLevel2", ignoreScan);
});
</script>

<style scoped>
.col-4 {
  line-height: 30px;
}

.autoscroll-container {
  margin: 0px 2px;
  max-width: 95%;
  height: 28px;
  display: inline-flex;
  align-items: center;
  /* WebkitBoxAlign: center; */
  white-space: nowrap;
  /* // outline: 0, */
  text-decoration: none;
  /* // border: 0, */
  vertical-align: middle;
  /* // boxSizing: 'border-box', */
  overflow: hidden;
}

.autoscroll-value {
  display: inline-block;
  position: relative;
  text-overflow: clip;
}

.autoscroll-value:hover {
  animation: scroll-rtl 15s linear forwards;
}

@keyframes scroll-rtl {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-60%);
  }
  50% {
    transform: translate(0);
  }
}
</style>
