export const CoreUIRoutes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/menu",
    name: "Main Menu",
    component: () => import("../views/MainMenu.vue"),
  },
  {
    path: "/desktopLaunch",
    name: "PDA Desktop Launch",
    component: () => import("../views/system/DesktopLaunch.vue"),
  },
  {
    path: "/deviceInit",
    name: "Device Initialisation",
    component: () => import("../views/system/DeviceInitialisation.vue"),
  },
  {
    path: "/devtest",
    name: "devTest",
    component: () => import("../views/devTest.vue"),
  },
  {
    path: "/stockCreation",
    name: "Stock Create",
    component: () => import("../views/StockCreateTest.vue"),
  },
  {
    path: "/template",
    name: "Template",
    component: () => import("../views/Template.vue"),
  }
];
