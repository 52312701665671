import { reactive, ref } from "vue";
import { saveAllLocalStorage } from "@/hooks/useLocalStorage.js";

const sessionDataDefaults = {
  sessionGuid: null,
  personnel: {
    number: null,
    displayName: "",
    shopNumber: null,
    warehouseNumber: null,
    level: null,
    groups: [],
  },
};

const sessionShopDefaults = {
  number: null,
  name: "",
  remember: false,
};

const sessionWarehouseDefaults = {
  number: null,
  name: "",
  remember: false,
};

// Set session to defaults
export const sessionData = reactive(Object.assign({}, sessionDataDefaults));
export const sessionShop = reactive(Object.assign({}, sessionShopDefaults));
export const sessionWarehouse = reactive(Object.assign({}, sessionWarehouseDefaults));

export const resetSessionData = () => {
  Object.assign(sessionData, sessionDataDefaults);
  saveAllLocalStorage();
};

export const resetSessionShop = () => {
  Object.assign(sessionShop, sessionShopDefaults);
  saveAllLocalStorage();
};

export const resetSessionWarehouse = () => {
  Object.assign(sessionWarehouse, sessionShopDefaults);
  saveAllLocalStorage();
};
export const sessionSelectedStockTake = ref(0);
