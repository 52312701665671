<template>
  <div class="row margin-bottom-tiny">
    <div
      v-if="fullWidth && !hideLabel"
      class="input-label margin-top-tiny col-12 margin-x"
      style="max-width: 500px; text-align: left !important"
    >
      {{ label }}
    </div>
    <div
      v-else-if="!hideLabel"
      class="input-label margin-top-tiny"
      v-bind:class="props.longerLabel ? 'col-4' : 'col-3'"
    >
      {{ label }}
    </div>
    <div
      class="padding-right"
      v-bind:class="{
        'col-12 margin-x padding-right-big': hideLabel || fullWidth,
        'margin-top-tony': fullWidth,
        'col-8 padding-left-none': longerLabel,
        'col-9 padding-left-none': !longerLabel,
      }"
    >
      <select
        v-bind:required="required"
        v-model="value"
        class="select-full"
        v-bind:disabled="readonly"
      >
        <option value="" selected disabled hidden>
          {{ ui.placeholderText }}
        </option>

        <option
          v-for="(option, index) in ui.options"
          v-bind:key="index"
          v-bind:value="option.value"
        >
          {{ option.label ?? option.value }}
        </option>
      </select>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, reactive } from "vue";

const value = defineModel();

const props = defineProps({
  label: {
    type: String,
  },
  options: {
    type: Array,
    required: true,
  },
  longerLabel: {
    type: Boolean,
    default: false,
  },
  defaultOption: {
    type: String,
    default: "",
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: true,
  },
  valueField: {
    type: String,
    required: false,
  },
  labelField: {
    type: String,
    required: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
});

const ui = reactive({
  placeholderText: "Select...",
  options: [],
});

onBeforeMount(() => {
  //Make the values smart
  for (let option of props.options) {
    //Just an array of options, value has to be the same as what we show
    if (typeof option === "string") {
      console.log(true);
      ui.options.push({ label: option, value: option });
      continue;
    }

    let newItem = { value: option?.value, label: option?.label };

    if (props.valueField !== undefined) {
      newItem.value = option[props.valueField];
    }

    if (props.labelField !== undefined) {
      newItem.label = option[props.labelField];
    }

    ui.options.push(newItem);
  }
});

onMounted(() => {});
</script>

<style scoped></style>
