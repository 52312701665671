<template>
  <com-modal-body class="text-unselectable">
    <h1 class="text-center">Select Shop</h1>
    <hr />
    <h4 style="font-size: 12px">
      Your current login credentials are set to 'All Shops'. Please Select the
      shop you are working at from the list shown below.
    </h4>

    <br />

    <com-selection-input
      v-model="ui.selectedShop"
      v-bind:options="ui.shopList"
      value-field="shopNumber"
      label-field="shopName"
      hide-label
    />

    <div style="min-height: 20px">
      <h3
        v-if="ui.showError"
        class="text-danger"
        style="font-size: 15px; margin: auto"
      >
        You must select a shop to continue
      </h3>
    </div>
    <br />
    <div class="container row margin-bottom left">
      <div class="col-2">
        <input
          id="remember-check"
          v-model="ui.rememberChoice"
          type="checkbox"
        />
      </div>
      <div class="col-10">
        <label style="font-size: 13px" for="remember-check"
          >Remember until I log out
        </label>
      </div>
    </div>

    <!--                Cancel Button-->
    <button class="btn-default height-med width-med" @click="cancelCallback">
      Cancel
    </button>
    <button
      class="btn-default btn-action height-med width-med right"
      @click="onClickContinue"
    >
      Continue
    </button>
  </com-modal-body>
</template>

<script setup>
import { reactive } from "vue";
import { sessionShop } from "@/store/sessionStore.js";
import { headOfficeCache } from "@/store/headOfficeStore.js";
import ComModalBody from "@/components/ui/ComModalBody.vue";
import ComSelectionInput from "@/components/inputFields/ComSelectionInput.vue";

const props = defineProps({
  desktop: {
    type: Boolean,
    default: false,
  },
  confirmCallback: {
    type: Function,
    default: null,
  },
  cancelCallback: {
    type: Function,
    default: null,
  },
});

const ui = reactive({
  rememberChoice: false,
  selectedShop: "",
  shopList: headOfficeCache.shopList,
  showError: false,
});

const onClickContinue = function () {
  if (ui.selectedShop === "") {
    ui.showError = true;
    return;
  }

  sessionShop.name = ui.selectedShop.shopName;
  sessionShop.number = ui.selectedShop.shopNumber;
  sessionShop.remember = ui.rememberChoice;

  props.confirmCallback(ui.selectedShop.shopNumber);
};
</script>

<style scoped>
#remember-check {
  display: inline !important;
  height: 25px;
  width: 25px;
  margin: 0;
}
</style>
