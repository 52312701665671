// SktcodeLookup
export const searchMethods = [
  {
    methodName: "Stock Code",
    value: "1",
  },
  {
    methodName: "Item Description",
    value: "2",
  },
  {
    methodName: "Suppliers Stock Code",
    value: "3",
  },
  {
    methodName: "Old Stock Code",
    value: "4",
  },
  {
    methodName: "Category",
    value: "5",
  },
  {
    methodName: "Manufacturers Code",
    value: "6",
  },
  {
    methodName: "Department",
    value: "7",
  },
  {
    methodName: "Primary Barcode",
    value: "8",
  },
  {
    methodName: "Supplier",
    value: "9",
  },
];
export const stockTypes = [
  {
    name: "General",
    value: "G",
  },
  {
    name: "Non Stock",
    value: "N",
  },
  {
    name: "Miscellaneous",
    value: "M",
  },
];
