export function sleep(duration) {
  return new Promise((success) => {
    setTimeout(() => success(), duration);
  });
}

export function setFocus(querySelector, selectAll = false, delayMs = 0) {

  const object = document.querySelector(querySelector);

  if (object) {
    window.setTimeout(function() {
      object.focus();

      if (selectAll === true) object.select();
    }, delayMs);
  }
}