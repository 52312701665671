import uiStorage from "@/store/uiStore.js";
import { f_camel_case } from "@/hooks/useFormats";
import { sessionShop } from "@/store/sessionStore.js";
// import {globalEmitter} from "@/hooks/useEmitter";

const loaderData = uiStorage.loader;

// Loader
const showLoader = function (title = "Please Wait...", description = "") {
  loaderData.enabled = true;
  loaderData.label = title;
  loaderData.description = description;
};
const hideLoader = function () {
  loaderData.enabled = false;
};

const openShopSelectModal = function () {
  return new Promise(function (success, error) {
    //If the user chose to remember their shop for this session... don't show the modal
    if (sessionShop.remember === true) {
      success();
      return;
    }

    let onSuccess = function (shopNumber) {
      uiStorage.shopSelect.visible = false;
      success(shopNumber);
    };

    let onCancel = function () {
      uiStorage.shopSelect.visible = false;
      error();
    };

    uiStorage.shopSelect.visible = true;
    uiStorage.shopSelect.onErrorCallBack = onCancel;
    uiStorage.shopSelect.onSelectCallBack = onSuccess;
  });
};

const showMultipleStkcodeSelection = function () {
  return new Promise(function (success, error) {
    let onSuccess = function (stkcode) {
      uiStorage.multipleStkcodeSelect.visible = false;
      success(stkcode);
    };

    let onCancel = function () {
      uiStorage.multipleStkcodeSelect.visible = false;
      error();
    };

    uiStorage.multipleStkcodeSelect.visible = true;
    uiStorage.multipleStkcodeSelect.onSuccess = onSuccess;
    uiStorage.multipleStkcodeSelect.onFail = onCancel;
  });
};

// Authorisation
/**
 * @desc Opens the authorisation modal for a given setting
 * @return {Promise<Personnel>}
 */

/**
 * @desc Check if a super user is logged in
 * @return {Promise<Personnel>}
 */
const openCheckSuperUserModal = function () {
  return new Promise(function (success, error) {
    let onSuccess = function () {
      uiStorage.checkSuperUserModal.enabled = false;
      success();
    };

    let onCancel = function () {
      uiStorage.checkSuperUserModal.enabled = false;
      error();
    };

    uiStorage.checkSuperUserModal.enabled = true;
    uiStorage.checkSuperUserModal.onCancel = onCancel;
    uiStorage.checkSuperUserModal.onSuccess = onSuccess;
  });
};

/**
 * @desc Returns if the processing flag is active
 * @return {boolean}
 */
const isProcessing = function () {
  return uiStorage.processing.active;
};

/**
 * @desc Sets the processing flag, which informs the till it's doing something
 * @param processing
 */
const setProcessing = function (processing = false) {
  uiStorage.processing.active = processing;
};

const showStkcodeLookup = function () {
  return new Promise((success, reject) => {
    uiStorage.stkcodeLookup.visible = true;

    uiStorage.stkcodeLookup.onSuccess = function (stkcode) {
      success(stkcode);
      closeStockCodeHelp();
    };

    uiStorage.stkcodeLookup.onFail = function () {
      reject("No Stock Code was selected");
      closeStockCodeHelp();
    };
  });
};

function closeStockCodeHelp() {
  uiStorage.stkcodeLookup.visible = false;
  uiStorage.stkcodeLookup.onFail = null;
  uiStorage.stkcodeLookup.onSuccess = null;
}

const showSupplierLookup = function (localCurrencySuppliersOnly = false) {
  return new Promise((success, reject) => {
    uiStorage.supplierLookup.visible = true;
    uiStorage.supplierLookup.localCurrencySuppliersOnly = localCurrencySuppliersOnly;

    uiStorage.supplierLookup.onSuccess = function (supplier) {
      success(supplier);
      closeSupplierLookup();
    };

    uiStorage.supplierLookup.onFail = function () {
      reject("No Supplier was selected");
      closeSupplierLookup();
    };
  });
};

function closeSupplierLookup() {
  uiStorage.supplierLookup.visible = false;
  uiStorage.supplierLookup.onFail = null;
  uiStorage.supplierLookup.onSuccess = null;
}

const showPurchaseOrderingStkcodeLookup = function (poNumber) {
  return new Promise((success, reject) => {

    uiStorage.purchaseOrderingStkcodeLookup.poNumber = poNumber;
    uiStorage.purchaseOrderingStkcodeLookup.visible  = true;

    uiStorage.purchaseOrderingStkcodeLookup.onSuccess = function (stkcode) {
      success(stkcode);
      closePurchaseOrderingStockCodeHelp();
    };

    uiStorage.purchaseOrderingStkcodeLookup.onFail = function () {
      reject("No Stock Code was selected");
      closePurchaseOrderingStockCodeHelp();
    };
  });
};

function closePurchaseOrderingStockCodeHelp() {
  uiStorage.purchaseOrderingStkcodeLookup.poNumber    = null;
  uiStorage.purchaseOrderingStkcodeLookup.visible     = false;
  uiStorage.purchaseOrderingStkcodeLookup.onFail      = null;
  uiStorage.purchaseOrderingStkcodeLookup.onSuccess   = null;
}

const showGoodsInStkcodeLookup = function (supplierNumber, suppliersDeliveryReference, poNumber) {
  return new Promise((success, reject) => {

    uiStorage.goodsInStkcodeLookup.supplierNumber             = supplierNumber;
    uiStorage.goodsInStkcodeLookup.suppliersDeliveryReference = suppliersDeliveryReference;
    uiStorage.goodsInStkcodeLookup.poNumber                   = poNumber;

    uiStorage.goodsInStkcodeLookup.visible = true;

    uiStorage.goodsInStkcodeLookup.onSuccess = function (stkcode) {
      success(stkcode);
      closeGoodsInStockCodeHelp();
    };

    uiStorage.goodsInStkcodeLookup.onFail = function () {
      reject("No Stock Code was selected");
      closeGoodsInStockCodeHelp();
    };
  });
};

function closeGoodsInStockCodeHelp() {
  uiStorage.goodsInStkcodeLookup.supplierNumber             = null;
  uiStorage.goodsInStkcodeLookup.suppliersDeliveryReference = null;
  uiStorage.goodsInStkcodeLookup.poNumber                   = null;
  uiStorage.goodsInStkcodeLookup.visible                    = false;
  uiStorage.goodsInStkcodeLookup.onFail                     = null;
  uiStorage.goodsInStkcodeLookup.onSuccess                  = null;
}
function debounceInput(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export {
  showLoader,
  hideLoader,
  isProcessing,
  setProcessing,
  openCheckSuperUserModal,
  openShopSelectModal,
  showMultipleStkcodeSelection,
  showStkcodeLookup,
  showSupplierLookup,
  showPurchaseOrderingStkcodeLookup,
  showGoodsInStkcodeLookup,
  debounceInput,
  loaderData,
};

// emitter.on('timedOut', function () {
//     closeKeyboard();
//     closeAlert();
// });
