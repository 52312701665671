export function f_money(value) {
  return "£" + parseFloat(value).toFixed(2);
}

const f_numeric = function (value, precision) {
  if (typeof precision === "undefined") precision = 2;

  return parseFloat(value).toFixed(precision);
};
const f_plural = function (word, input) {
  let length = 1;

  if (typeof input === "number") length = input;
  else if (Array.isArray(input)) length = input.length;

  if (length === 1) return word;

  return word + "s";
};
const f_date = function (value) {
  if (value === "" || value === null) return "";

  let date = new Date(value);

  return (
    ("00" + date.getDate()).slice(-2) +
    "/" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear()
  );
};

const f_short_date = function (value) {
  if (value === "" || value === null) return "";

  let date = new Date(value);

  return (
    ("00" + date.getDate()).slice(-2) +
    "/" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear().toString().substr(-2)
  );
};

const f_datetime = function (value, showSeconds = true) {
  if (value === "" || value === null) return "";

  value = value.replace(/-/g, "/");
  value = value.replace("T", " ");

  let date = new Date(value);
  let string =
    ("00" + date.getDate()).slice(-2) +
    "/" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear() +
    " " +
    ("00" + date.getHours()).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2);

  if (showSeconds === true)
    string += ":" + ("00" + date.getSeconds()).slice(-2);

  return string;
};
const f_time_of_datetime = function (value) {
  if (value === "" || value === null) return "";

  let date = new Date(value.replace(/-/g, "/"));
  let am_pm = " am";

  if (date.getHours() > 12) am_pm = " pm";

  return (
    ("00" + date.getHours()).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2) +
    am_pm
  );
};
const f_camel_case = function (value) {
  if (typeof value === "undefined") return "";

  let splitStr = value.toLowerCase().split(" ");

  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(" ");
};
const f_till_counter = function (value) {
  return value.toString().padStart(11, "0");
};

export {
  f_numeric,
  f_plural,
  f_date,
  f_short_date,
  f_datetime,
  f_time_of_datetime,
  f_camel_case,
  f_till_counter,
};
