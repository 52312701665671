<template>
  <div class="container padding-none">
    <div class="settings-page-content">
      <div class="alert-danger margin-tiny">
        <strong>Warning!</strong> <br />These settings should only be adjusted
        under the advice of Corby+Fellas or your EPOS Administrator
      </div>

      <h4 class="text-center margin-top-small">WinRetail Server Settings</h4>
      <hr />
      <com-checkbox longer-label label="Use https:" v-model="ui.useHttps" />
      <com-string-input
        longer-label
        v-model="ui.pasIp"
        label="Host:"
        v-bind:max-length="9999"
      />
      <com-num-input v-model="ui.pasPort" longer-label label="Port:" />
    </div>
    <div class="pda-bottom-nav container">
      <div class="row">
        <div class="col-3 nav-slot">
          <button
            class="nav-button navbutton-label-text"
            v-on:click="onClickBack"
          >
            Discard <br />
            & Close
          </button>
        </div>
        <div class="col-3 nav-slot"></div>
        <div class="col-3 nav-slot">
          <button
            class="nav-button navbutton-label-text"
            v-on:click="onClickTestPas"
          >
            Test
          </button>
        </div>
        <div class="col-3 nav-slot">
          <button
            class="nav-button navbutton-label-text"
            v-on:click="onClickSave"
          >
            Save & <br />Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, reactive } from "vue";
import * as ZXingBrowser from "@zxing/browser";
import { deviceData } from "@/store/deviceStore.js";
import { saveAllLocalStorage } from "@/hooks/useLocalStorage.js";
import uiStore from "@/store/uiStore.js";
import ComNumInput from "@/components/inputFields/ComNumInput.vue";
import ComCheckbox from "@/components/inputFields/ComCheckbox.vue";
import ComStringInput from "@/components/inputFields/ComStringInput.vue";
import { testNewPasEndpoint, updatePasEndpoint } from "@/hooks/usePas.js";
import { showNotificationSync } from "@/hooks/useModals.js";

const ui = reactive({
  testPassed: false,
  useHttps: deviceData.useHttps,
  pasHost: deviceData.pasHost,
  pasPort: deviceData.pasPort,
});

async function onClickTestPas() {
  try {
    showNotificationSync({
      content: "Testing Connection...",
      smallText: true,
    });
    let urlToTest = `http${ui.useHttps ? "s" : ""}://${ui.pasHost}${
      ui.pasPort === 0 ? "" : ":" + ui.pasPort
    }`;

    await testNewPasEndpoint(urlToTest);

    showNotificationSync({
      content: "Connected To WinRetail Successfully",
      smallText: true,
    });
  } catch (e) {
    showNotificationSync({
      content: "Could not connect to WinRetail",
      smallText: true,
    });
  }
}

function onClickSave() {
  deviceData.pasPort = ui.pasPort;
  deviceData.useHttps = ui.useHttps;
  deviceData.pasHost = ui.pasHost;
  saveAllLocalStorage();
  updatePasEndpoint();
  uiStore.settingsPage.show = "";
}

function onClickBack() {
  uiStore.settingsPage.show = "";
}

onMounted(async function () {
  const videoInputDevices =
    await ZXingBrowser.BrowserCodeReader.listVideoInputDevices();

  if (videoInputDevices.length > 0) {
    ui.camerasAllowed = true;
    ui.cameraEnabled = deviceData.cameraScanningEnabled;
    ui.selectedCamera = deviceData.selectedCamera;

    for (let device of videoInputDevices) {
      ui.cameraList.push(device.label);
    }
  }
});

onUnmounted(function () {
  deviceData.cameraScanningEnabled = ui.cameraEnabled;
  deviceData.selectedCamera = ui.selectedCamera;
  saveAllLocalStorage();
});
</script>

<style scoped></style>
