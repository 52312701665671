<template>
  <div
    :class="noRow ? 'contents' : 'row margin-bottom-tiny'"
  >
    <div
      v-if="compLabelClass"
      class="input-label margin-top-tiny"
      :class="compLabelClass"
      :style="compLabelStyle"
    >
      {{ label }}
      <slot name="labelSlot"></slot>
    </div>

    <div
      :class="compInputClass"
      :style="compInputStyle"
    >
      <input
        v-if="textArea === false"
        v-model="value"
        :readonly="readonly"
        :disabled="readonly"
        :maxlength="maxLength"
        :class="readonly === true ? 'text-unselectable' : ''"
        :placeholder="placeholder"
        @keydown.enter="emit('key-enter')"
      />
      <textarea
        v-else
        v-model="value"
        :readonly="readonly"
        :disabled="readonly"
        :maxlength="maxLength"
        :class="readonly === true ? 'text-unselectable' : ''"
        :placeholder="placeholder"
        @keydown.enter="emit('key-enter')"
      >
      </textarea>
    </div>

    <div
      v-if="compSlotClass"
      :class="compSlotClass"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const value = defineModel();
const emit = defineEmits(["key-enter"]);

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  maxLength: {
    type: Number,
    default: 30,
  },
  actionButton: {
    type: Boolean,
    default: false,
  },
  twoActionButtons: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "",
  },
  longerLabel: {
    type: Boolean,
    default: false,
  },
  removeLabel: {
    type: Boolean,
    default: false,
  },
  textArea: {
    type: Boolean,
    default: false,
  },
  textAreaHeight: {
    type: String,
    default: "10ch",
  },
  labelCols: {
    type: Number,
    default: null,
  },
  fieldCols: {
    type: Number,
    default: null,
  },
  slotCols: {
    type: Number,
    default: null,
  },
  noRow: {
    type: Boolean,
    default: false,
  }
});

const compLabelClass = computed(() => {
  if (props.removeLabel) return null;
  if (props.fullWidth)   return 'col-12 margin-x';
  if (props.labelCols)   return `col-${props.labelCols}`;
  return props.longerLabel ? 'col-4' : 'col-3';
});

const compLabelStyle = computed(() => 
  props.fullWidth ? 'max-width: 500px; text-align: left !important' : '');

const compInputClass = computed(() => {  
  if (props.fullWidth)        return 'padding-x col-12 margin-top-tiny';
  if (props.fieldCols)        return `padding-right padding-left-none col-${props.fieldCols}`;
  if (props.actionButton)     return props.longerLabel ? 'padding-none col-6' :'padding-none col-7';
  if (props.twoActionButtons) return props.longerLabel ? 'padding-none col-4' :'padding-none col-5';
  return props.longerLabel ? 'padding-right padding-left-none col-8' : 'padding-right padding-left-none col-9';
})

const compInputStyle = computed(() => 
  props.fullWidth ? 'max-width: 500px' : '');

const compSlotClass = computed(() => {
  if (props.slotCols)         return `padding-none col-${props.slotCols}`;
  if (props.actionButton)     return `padding-none col-2`;
  if (props.twoActionButtons) return `padding-none col-3`;
  return null;
})

</script>

<style scoped>
textarea {
  height: v-bind(textAreaHeight);
}

.contents {
  display: contents;
}
</style>
