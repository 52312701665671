<template>
  <com-modal-body>
    <div class="container padding-x-none">
      <div class="row margin-bottom-tiny">
        <div
          class="col-4 small padding-left-none padding-right-half text-unselectable"
          style="text-align: right"
        >
          Search By
        </div>

        <div class="col-8 padding-none">
          <select
            v-model="ui.selectedSearchMethod"
            style="max-width: 100% !important; height: 30px"
          >
            <option
              v-for="method in ui.searchMethods"
              :key="method.methodName"
              :value="method.value"
            >
              {{ method.methodName }}
            </option>
          </select>
        </div>
      </div>

      <div class="row margin-bottom-tiny">
        <div
          class="col-4 small padding-left-none padding-right-half text-unselectable"
          style="text-align: right"
        >
          Stock Type
        </div>

        <div class="col-8 padding-none">
          <select
            v-model="ui.selectedStockType"
            style="max-width: 100% !important; height: 30px"
          >
            <option
              v-for="types in ui.stockTypes"
              :key="types.name"
              :value="types.value"
            >
              {{ types.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row margin-bottom-tiny">
        <div
          class="col-4 small padding-left-none padding-right-half text-unselectable"
          style="text-align: right"
        >
          Search Text
        </div>
        <div class="col-8 padding-none">
          <input
            style="margin-right: 10px"
            class="width-xlarge"
            v-model="ui.searchTerm"
            v-on:keydown.enter="onClickSearch"
          />
        </div>
      </div>

      <!--      <button-->
      <!--        class="btn-default btn-action btn-small width-xlarge center"-->
      <!--        @click="onClickSearch"-->
      <!--      >-->
      <!--        Search-->
      <!--      </button>-->

      <div
        v-if="compSearchInProgress"
        style="height: calc(63dvh - 30px); max-width: calc(100dvw - 20px)"
      >
        <com-loader style="padding-top: 100px" />
      </div>

      <div
        v-if="ui.searchResults.length === 0 && compSearchInProgress === false"
        style="height: calc(63dvh - 30px); max-width: calc(100dvw - 20px)"
      >
        <template v-if="ui.lastSearch.term !== ''">
          <h1 class="text-center" style="padding-top: 120px">
            No records found
          </h1>
          <p class="text-center bold">Consider adjusting your search terms.</p>
        </template>
      </div>

      <div
        v-if="ui.searchResults.length > 0 && compSearchInProgress === false"
        class="new-table margin-top-half"
        style="
          height: 65dvh;
          max-height: calc(63dvh - 30px);
          max-width: calc(100dvw - 20px);
          overflow-x: scroll !important;
        "
      >
        <table style="width: calc(100%); table-layout: fixed">
          <!--                        TABLE HEADERS-->
          <thead>
            <tr style="">
              <th style="width: 12ch">Stock Code</th>
              <th>Description</th>
            </tr>
          </thead>
          <!--                        TABLE DATA -->
          <tbody>
            <tr
              v-bind:class="
                ui.selectedStkCode === item.stkcode ? 'selected' : ''
              "
              v-for="item in ui.searchResults"
              :key="item.stkcode"
              @click="onRowSelected(item.stkcode)"
            >
              <td style="width: 10ch">{{ item.stkcode }}</td>
              <td>
                <div class="autoscroll-container">
                  <div
                    v-bind:class="
                      item.description.length > 24 ? 'autoscroll-value' : ''
                    "
                  >
                    {{ item.description }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row margin-top">
        <div class="col-5">
          <button
            v-on:click="onClickCancel"
            class="btn-default height-med"
            style="width: 100%"
          >
            Cancel
          </button>
        </div>
        <div class="col-5 offset-2">
          <button
            v-if="compHasSearchChanged"
            v-on:click="onClickSearch"
            class="btn-default btn-action height-med"
            style="width: 100%"
            v-bind:disabled="ui.searchTerm === ''"
          >
            Search
          </button>
          <button
            v-else
            v-on:click="onClickOk"
            class="btn-default btn-action height-med"
            style="width: 100%"
            v-bind:disabled="ui.selectedStkCode === ''"
          >
            Select
          </button>
        </div>
      </div>

      <!--      <div style="height: 30px" class="margin-top-tiny">-->
      <!--        <button-->
      <!--          class="right margin-left btn-default btn-small btn-action width-med"-->
      <!--          @click="onClickOk"-->
      <!--        >-->
      <!--          OK-->
      <!--        </button>-->
      <!--        <button-->
      <!--          class="right btn-default btn-small width-med"-->
      <!--          @click="onClickCancel"-->
      <!--        >-->
      <!--          Cancel-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
  </com-modal-body>
</template>

<script setup>
import { reactive, watch, onMounted, onUnmounted, computed } from "vue";
import { globalEmitter } from "@/hooks/useGlobalEmitter";
import { searchMethods, stockTypes } from "@/store/defaultsStore";
import { allSearchFunctions } from "@/hooks/useSearchFunctions";
import ComModalBody from "@/components/ui/ComModalBody.vue";
import ComLoader from "@/components/loaders/ComSearchLoader.vue";
import { getSupplierName } from "@/hooks/useSuppliers.js";

const props = defineProps({
  onSuccess: {
    type: Function,
    required: true,
  },
  onFail: {
    type: Function,
    required: true,
  },
});

const uiDefaults = {
  searchMethods,
  stockTypes,
  searchResults: [],
  selectedStockType: "G",
  selectedSearchMethod: "1",
  selectedStkCode: "",
  selectedRow: null,
  searchTerm: "",
  showModal: false,
  searchingStatus: {
    minTimeoutComplete: true,
    resultBackFromServer: true,
  },
  lastSearch: {
    term: "",
    method: "1",
    stockType: "G",
  },
};
const ui = reactive(Object.assign({}, uiDefaults));

const onClickCancel = function () {
  props.onFail();
};

/**
 * @desc Determines if we should be showing either the search button of the select button
 *        that chooses the item
 * @type {ComputedRef<boolean|undefined>}
 */
const compHasSearchChanged = computed(() => {
  //If we haven't searched yet always show search button (It will be disabled if term is blank anyway)
  if (ui.lastSearch.term === "") return true;

  if (ui.lastSearch.term !== ui.searchTerm) return true;

  if (ui.lastSearch.method !== ui.selectedSearchMethod) return true;

  if (ui.lastSearch.stockType !== ui.selectedStockType) return true;

  return false;
});

const compSearchInProgress = computed(() => {
  if (ui.searchingStatus.minTimeoutComplete === false) return true;
  if (ui.searchingStatus.resultBackFromServer === false) return true;

  return false;
});
const onClickOk = function () {
  if (ui.selectedStkCode.length !== 0) {
    props.onSuccess(ui.selectedStkCode);
  }

  props.onFail();
};

// Watch the selected search method and clear the search term if it exists
watch(
  () => ui.selectedSearchMethod,
  () => {
    ui.searchTerm = null;
  }
);

function onRowSelected(stkcode) {
  console.dir(stkcode);

  ui.selectedStkCode = stkcode;
}

async function onClickSearch() {
  ui.searchingStatus.resultBackFromServer = false;
  ui.searchingStatus.minTimeoutComplete = false;

  setTimeout(() => (ui.searchingStatus.minTimeoutComplete = true), 1000);

  ui.lastSearch = {
    term: ui.searchTerm,
    method: ui.selectedSearchMethod,
    stockType: ui.selectedStockType,
  };

  let term = ui.searchTerm;
  ui.searchResults.length = 0;
  switch (ui.selectedSearchMethod) {
    case "1":
      await allSearchFunctions
        .searchStkcode(term, ui.selectedStockType)
        .then((res) => {
          ui.searchResults.push(...res.results);
        });
      break;
    case "2":
      await allSearchFunctions
        .searchDescription(term, ui.selectedStockType)
        .then((res) => {
          ui.searchResults.push(...res.results);
        });
      break;
    case "3":
      await allSearchFunctions
        .searchSuppliersStkcode(term, ui.selectedStockType)
        .then((res) => {
          ui.searchResults.push(...res.results);
        });
      break;
    case "4":
      await allSearchFunctions
        .searchOldStkcode(term, ui.selectedStockType)
        .then((res) => {
          ui.searchResults.push(...res.results);
        });
      break;
    case "5":
      await allSearchFunctions
        .searchCategory(term, ui.selectedStockType)
        .then((res) => {
          ui.searchResults.push(...res.results);
        });
      break;
    case "6":
      await allSearchFunctions
        .searchManufacturersCode(term, ui.selectedStockType)
        .then((res) => {
          ui.searchResults.push(...res.results);
        });
      break;
    case "7":
      await allSearchFunctions
        .searchDepartment(term, ui.selectedStockType)
        .then((res) => {
          ui.searchResults.push(...res.results);
        });
      break;
    case "8":
      await allSearchFunctions
        .searchPrimaryBarcode(term, ui.selectedStockType)
        .then((res) => {
          ui.searchResults.push(...res.results);
        });
      break;
    case "9":
      await allSearchFunctions
        .searchSupplier(term, ui.selectedStockType)
        .then((res) => {
          ui.searchResults.push(...res.results);
        });
      break;
  }

  ui.searchingStatus.resultBackFromServer = true;
}

function ignoreScan() {
  console.log("Ignoring barcode scan");
}

onMounted(function () {
  //Don't want the prog underneath reacting to scans
  globalEmitter.on("barcodeScannedLevel2", ignoreScan);
});

onUnmounted(function () {
  //Release the listener
  globalEmitter.off("barcodeScannedLevel2", ignoreScan);
});
</script>

<style scoped>
.col-4 {
  line-height: 30px;
}

.autoscroll-container {
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 95%;
  height: 28px;
  display: inline-flex;
  align-items: center;
  /* WebkitBoxAlign: center; */
  white-space: nowrap;
  /* // outline: 0, */
  text-decoration: none;
  /* // border: 0, */
  vertical-align: middle;
  /* // boxSizing: 'border-box', */
  overflow: hidden;
}

.autoscroll-value {
  display: inline-block;
  position: relative;
  text-overflow: clip;
}

.autoscroll-value:hover {
  animation: scroll-rtl 15s linear forwards;
}

@keyframes scroll-rtl {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-60%);
  }
  50% {
    transform: translate(0);
  }
}
</style>
