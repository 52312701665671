<template>
  <com-modal-body>
    <h3>Multiple Stock Codes</h3>
    <p>
      The barcode entered is linked to more than one stock code. Please select
      the correct stock item to continue
    </p>
    <div
      class="new-table margin-top-half"
      style="
        height: 50vh;
        max-width: calc(100dvw - 40px);
        overflow-x: scroll !important;
      "
    >
      <table style="">
        <!--                        TABLE HEADERS-->
        <thead>
          <tr style="">
            <th style="width: 12ch">Stock Code</th>
            <th>Description</th>
          </tr>
        </thead>
        <!--                        TABLE DATA -->
        <tbody>
          <tr
            v-bind:class="ui.selectedStkCode === item.stkcode ? 'selected' : ''"
            v-for="item in uiStorage.multipleStkcodeSelect.stkmstList"
            :key="item.stkcode"
            @click="onRowSelected(item.stkcode)"
          >
            <td style="width: 10ch">{{ item.stkcode }}</td>
            <td style="max-width: calc(100% - 10ch)">
              {{ item.description }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="height: 30px" class="margin-top-tiny">
      <button class="right margin-left" @click="onClickCancel">Cancel</button>
      <button class="right" @click="onClickOk">OK</button>
    </div>
  </com-modal-body>
</template>

<script setup>
import { reactive, watch, onMounted, onUnmounted } from "vue";
import { globalEmitter } from "@/hooks/useGlobalEmitter";
import { searchMethods, stockTypes } from "@/store/defaultsStore";
import { allSearchFunctions } from "@/hooks/useSearchFunctions";
import uiStorage from "@/store/uiStore.js";
import ComModalBody from "@/components/ui/ComModalBody.vue";

const props = defineProps({
  onSuccess: {
    type: Function,
    required: true,
  },
  onFail: {
    type: Function,
    required: true,
  },
});

const ui = reactive({
  selectedStkCode: "",
});

const onClickCancel = function () {
  props.onFail();
};

const onClickOk = function () {
  if (ui.selectedStkCode.length !== 0) {
    props.onSuccess(ui.selectedStkCode);
  }

  props.onFail();
};

function onRowSelected(stkcode) {
  console.dir(stkcode);

  ui.selectedStkCode = stkcode;
}

function ignoreScan() {
  console.log("Ignoring barcode scan");
}

onMounted(function () {
  //Don't want the prog underneath reacting to scans
  globalEmitter.on("barcodeScannedLevel2", ignoreScan);
});

onUnmounted(function () {
  //Release the listener
  globalEmitter.off("barcodeScannedLevel2", ignoreScan);
});
</script>

<style scoped></style>
