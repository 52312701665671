import { reactive } from "vue";

export const ModalStore = reactive({
  alert: {
    enabled: false,
    title: "",
    content: "",
    danger: false,
    closeCallback: null,
    sound: "negative",
  },
  confirm: {
    enabled: false,
    title: "",
    content: "",
    yesCallback: null,
    noCallback: null,
    sound: "notify",
    yesLabel: "Yes",
    noLabel: "No",
  },
  notification: {
    enabled: false,
    content: "",
    smallText: false,
    timeout: 5,
    clickToClose: true,
    closeCallback: null,
    sound: "notify",
  },
  stkcodeHelp: {
    enabled: false,
    yesCallback: null,
    noCallback: null,
  },
  storeSelect: {
    enabled: false,
    yesCallback: null,
    noCallback: null,
  },
});
