import errorFatal from "../assets/sounds/fatalError.mp3";
import notifyStandard from "../assets/sounds/notifyStandard.mp3";
import notifyNegative from "../assets/sounds/notifyNegative.mp3";
import notifySuccess from "../assets/sounds/notifySuccess.mp3";
import { logDev } from "@/hooks/useEnvironment.js";
export async function playSound(soundType) {
  let sound = null;

  switch (soundType) {
    case "negative":
      sound = notifyNegative;
      break;
    case "fatalError":
      sound = errorFatal;
      break;
    case "success":
      sound = notifySuccess;
      break;
    case "notify":
      sound = notifyStandard;
      break;
  }

  if (sound === null) return;

  const audioElement = new Audio(sound);
  try {
    await audioElement.play();
  } catch (e) {
    logDev("Failed to play Sound");
    logDev(e);
  }
}
