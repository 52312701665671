export const StoreOperationsRoutes = [
  /***********************************************************
   ** Price Checks                                          **
   ***********************************************************/
  {
    path: "/priceChecks",
    name: "Price Checks",
    component: () =>
      import("../views/storeOperations/priceChecks/BatchSettings.vue"),
  },
  {
    path: "/priceChecks",
    name: "Price Checks Main",
    component: () => import("../views/storeOperations/priceChecks/Main.vue"),
  },

  /***********************************************************
   ** Stock Enquiry                                         **
   ***********************************************************/
  {
    path: "/stockEnquiry",
    name: "Stock Enquiry",
    component: () =>
      import("../views/storeOperations/stockEnquiry/MainView.vue"),
  },

  /***********************************************************
   ** Shop Stock Taking                                     **
   ***********************************************************/
  {
    path: "/storeOperations/ShopStockTaking",
    name: "Shop Stock Taking",
    component: () =>
      import("../views/storeOperations/shopStockTaking/SelectStockTake.vue"),
  },
  {
    path: "/storeOperations/ShopStockTaking/AddLines",
    name: "Shop Stock Taking Add Lines",
    component: () =>
      import("../views/storeOperations/shopStockTaking/Main.vue"),
  },

  //Stock Adjustments
  {
    path: "/storeOperations/StockAdjustment",
    name: "Stock Adjustments",
    component: () =>
      import("../views/storeOperations/stockAdjustment/Main.vue"),
  },

  /***********************************************************
   ** Label Printing                                        **
   ***********************************************************/

  {
    path: "/labelPrinting",
    name: "Label Printing",
    component: () =>
      import("../views/storeOperations/labelPrinting/PrinterSettings.vue"),
  },
  {
    path: "/labelPrinting",
    name: "Label Printing Main",
    component: () => import("../views/storeOperations/labelPrinting/Main.vue"),
  },

  /***********************************************************
   ** Campaign Coupon Enquiry                               **
   ***********************************************************/

  {
    path: "/redeemCoupon",
    name: "Redeem Coupon",
    component: () => import("../views/storeOperations/redeemCoupon/Main.vue"),
  },

  /***********************************************************
   ** Campaign Coupon Enquiry                               **
   ***********************************************************/

  {
    path: "/redeemGiftCard",
    name: "Redeem Gift Card",
    component: () => import("../views/storeOperations/redeemGiftCard/Main.vue"),
  },

  /***********************************************************
   ** Purchase Ordering                                     **
   ***********************************************************/

  {
    path: "/purchaseOrdering",
    name: "Purchase Ordering",
    component: () => import("../views/storeOperations/purchaseOrdering/PurchaseOrderList.vue"),
  },
  {
    path: "/purchaseOrdering/createPurchaseOrder",
    name: "Create Purchase Order",
    component: () => import("../views/storeOperations/purchaseOrdering/CreatePurchaseOrder.vue"),
  },
  {
    path: "/purchaseOrdering/purchaseOrderDetails",
    name: "Purchase Order Details",
    component: () => import("../views/storeOperations/purchaseOrdering/PurchaseOrderDetails.vue"),
  },
];
