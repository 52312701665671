import { ModalStore } from "@/store/modalStore.js";
import uiStore from "@/store/uiStore.js";

/**
 *  * @param options {{title: string, content: string, danger: boolean, sound: ?string}}
 * @return {Promise<void>}
 */
export async function showAlert(options) {
  return new Promise((success) => {
    uiStore.mask.enabled = true;
    ModalStore.alert.enabled = true;
    ModalStore.alert.title = options.title ?? "Warning";
    ModalStore.alert.content = options.content ?? "";
    ModalStore.alert.danger = options.danger ?? false;

    ModalStore.alert.sound =
      options.sound === undefined ? "negative" : options.sound;

    ModalStore.alert.closeCallback = function () {
      ModalStore.alert.enabled = false;
      uiStore.mask.enabled = false;
      success();
    };
  });
}

/**
 * @desc Displays a message to the user
 * @param options {{title: string, content: string, danger: boolean, sound: ?string}}
 * @return {Promise<unknown>}
 */
export async function showConfirm(options) {
  return new Promise(function (success, error) {
    uiStore.mask.enabled = true;
    ModalStore.confirm.enabled = true;
    ModalStore.confirm.title = options.title ?? "Warning";
    ModalStore.confirm.content = options.content ?? "";
    ModalStore.confirm.danger = options.danger ?? false;
    ModalStore.confirm.yesLabel = options.yesLabel ?? "Yes";
    ModalStore.confirm.noLabel = options.noLabel ?? "No";

    ModalStore.confirm.sound =
      options.sound === undefined ? "notify" : options.sound;

    ModalStore.confirm.yesCallback = function () {
      ModalStore.confirm.enabled = false;
      uiStore.mask.enabled = false;
      success();
    };

    ModalStore.confirm.noCallback = function () {
      ModalStore.confirm.enabled = false;
      uiStore.mask.enabled = false;
      error();
    };
  });
}

/**
 * @desc Local function that contains Modalstore settings that both the exported Async and Sync
 * versions use
 * @param options {{content: string, timeout: number, clickToClose: boolean, smallText: boolean, sound: ?string}} forwarded from parent functions
 */
function displayNotificationModal(options) {
  ModalStore.notification.enabled = true;
  ModalStore.notification.content = options.content ?? "";
  ModalStore.notification.timeout = options.timeout ?? 5;
  ModalStore.notification.clickToClose = options.clickToClose ?? true;
  ModalStore.notification.smallText = options.smallText ?? false;

  ModalStore.notification.sound = options.sound ?? "notify";
}

/**
 * @desc  Shows a notification at the top of the application and resolves promise once it's gone
 * @param options {{content: string, timeout: number, clickToClose: boolean, smallText: boolean, sound: ?string}}
 * @return {Promise<unknown>}
 */
export async function showNotificationAsync(options) {
  return new Promise(function (success) {
    displayNotificationModal(options);

    ModalStore.notification.closeCallback = function () {
      ModalStore.notification.enabled = false;
      success();
    };
  });
}

/**
 * @desc  Shows a notification at the top of the application, synchronous so code can continue to run
 * @param options {{content: string, timeout?: number, clickToClose?: boolean, smallText?: boolean, sound: ?string}}
 * @return void
 */
export function showNotificationSync(options) {
  displayNotificationModal(options);

  ModalStore.notification.closeCallback = function () {
    ModalStore.notification.enabled = false;
  };
}
