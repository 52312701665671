import { createRouter, createWebHistory } from "vue-router";
import { CoreUIRoutes } from "@/router/coreUIRoutes.js";
import { StoreOperationsRoutes } from "@/router/storeOperationsRoutes.js";
import { SharedRoutes } from "@/router/sharedRoutes.js";

const routes = [...CoreUIRoutes, ...StoreOperationsRoutes, ...SharedRoutes];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
