import { useRouter } from "vue-router";
import { globalEmitter } from "./useGlobalEmitter.js";
import { reactive } from "vue";

const routeData = reactive({
  routes: {
    history: [],
    current: {
      name: "",
      params: {},
    },
  },
});

export default function () {
  const router = useRouter();
  const currentRoute = routeData.routes.current;
  const routeHistory = routeData.routes.history;

  const goToPage = function (routeName, createHistory = true, params = {}) {
    // If we can add this to our history
    if (createHistory === true) {
      // If we don't have a name, then it would be from the main
      let name = routeName;

      if (name === "") name = "Menu";

      routeHistory.push({
        name: name,
        params: params,
      });
    }

    // If we got to the main, clear the history
    if (routeName === "Main Menu") {
      routeHistory.length = 0;
    }

    if (routeHistory.length > 10)
      routeHistory.splice(0, routeHistory.length - 5);

    currentRoute.name = routeName;
    currentRoute.params = params;

    // Emit to the world what route the user is currently on
    globalEmitter.emit("pageChanged", routeName);

    router.push({
      name: routeName,
      params: params,
    });
  };

  const getPageParams = () => {
    return routeData.routes.params;
  };
  const getLastPageName = function () {
    // No History? Then must be from the main
    if (routeHistory.length <= 1) {
      return "Main Menu";
    }

    return routeHistory[routeHistory.length - 2].name;
  };
  const goToBack = function (additionalParams = {}) {
    let lastRoute = {
      name: "Main Menu",
      params: {},
    };

    let canContinue = true;

    while (canContinue) {
      // Get the last page we were o
      if (routeHistory.length > 0) {
        lastRoute = routeHistory[routeHistory.length - 1];
      } else {
        lastRoute.name = "Main Menu";
      }

      if (lastRoute.name === currentRoute.name) {
        routeHistory.pop();
      } else {
        canContinue = false;
      }
    }

    // Make sure we aren't given a blank
    if (lastRoute.name === "") {
      lastRoute.name = "Main Menu";
    }

    // If we want to populate some params
    if (additionalParams !== {}) {
      Object.assign(lastRoute.params, additionalParams);
    }

    goToPage(lastRoute.name, false, lastRoute.params);
  };

  const getPageParam = (key, defaultValue) => {
    return routeData.routes.params[key] ?? defaultValue;
  };

  const setPageParam = (key, value) => {
    console.log(" " + key + " " + value + " ");
    routeData.routes.params[key] = value;
  };

  const getPageName = function () {
    return routeData.routes.current.name;
  };
  return {
    goToPage,
    goToBack,
    getPageName,
    setPageParam,
    getPageParams,
    getPageParam,
    getLastPageName,
  };
}
