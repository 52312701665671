<template>
  <div class="row margin-bottom-tiny">
    <div
      class="input-label"
      :class="props.longerLabel ? 'col-4' : 'col-3'"
    >
      <label
        v-if="!rightLabel"
      >
        {{ label }}
      </label>
    </div>
    <div class="padding-right padding-left-none col-1">
      <input
        id="checkbox"
        v-model="value"
        class="com-checkbox left"
        type="checkbox"
        :readonly="readonly"
        :disabled="readonly"
      />
    </div>
    <div
      v-if="rightLabel"
      style="text-align: left; margin: auto 0;"
      :class="props.longerLabel ? 'col-7' : 'col-8'"
    >
      {{ label }}
    </div>
  </div>
</template>

<script setup>
const value = defineModel();

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  longerLabel: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  rightLabel: {
    type: Boolean,
    default: false,
  }
});
</script>

<style scoped>
.com-checkbox {
  margin-top: 5px;
  margin-left: 5px;
  height: 25px;
  width: 25px;
}
</style>
