<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container-hidden">
        <div style="position: relative">
          <button
            class="modal-close-button btn btn-danger"
            @click="emit('hideModal')"
          >
            X
          </button>
        </div>

        <video id="video-preview" style="width: 100%"></video>
      </div>
    </div>
  </div>
</template>

<script setup>
import { BrowserMultiFormatReader } from "@zxing/browser";
import * as ZXingBrowser from "@zxing/browser";
import { onMounted } from "vue";
import { deviceData } from "@/store/deviceStore.js";

const emit = defineEmits(["found_barcode", "hideModal"]);

onMounted(async () => {
  const codeReader = new BrowserMultiFormatReader();

  const videoInputDevices =
    await ZXingBrowser.BrowserCodeReader.listVideoInputDevices();
  console.log(videoInputDevices);

  let selectedDeviceId = null;

  // choose your media device (webcam, frontal camera, back camera, etc.)
  //If there is more than one camera, the first is likely to be the selfie camera, so select the next, otherwise if we only have one camera, use that...
  if (deviceData.selectedCamera !== "")
    selectedDeviceId = videoInputDevices.find(function (device) {
      return device.label === deviceData.selectedCamera;
    }).deviceId;

  if (selectedDeviceId === null && videoInputDevices.length > 1)
    selectedDeviceId = videoInputDevices[1].deviceId;
  else selectedDeviceId = videoInputDevices[0].deviceId;

  if (selectedDeviceId === null || selectedDeviceId === undefined)
    emit("close-modal");

  console.log(`Started decode from camera with id ${selectedDeviceId}`);

  const previewElem = document.querySelector("#video-preview");

  // you can use the controls to stop() the scan or switchTorch() if available
  codeReader
    .decodeOnceFromVideoDevice(selectedDeviceId, previewElem)
    .then((result) => {
      console.log(result);
      emit("found_barcode", result.text);
      emit("hideModal");
    })
    .catch((err) => {
      console.error(err);
    });

  // stops scanning after 20 seconds
  //     setTimeout(() => controls.stop(), 20000);
});
</script>

<style scoped>
video {
  background-color: red;
}
</style>
