<template xmlns="http://www.w3.org/1999/html">
  <com-modal-body>
    <div class="container padding-x-none">
      <div class="row margin-bottom-tiny">
        <div
          class="col-12 small padding-left-none padding-right-half text-unselectable"
          style="text-align: center"
        >
          <h2>Goods In Product Lookup</h2>
        </div>
        <div
          class="col-12 small padding-left-none padding-right-half text-unselectable"
          style="text-align: center"
        >
          <input id="expected" v-model="ui.selectedSearchType" type="radio" value="expected" @change="onClickSearch">
          <label for="expected">Expected</label>
          <input id="supplier" v-model="ui.selectedSearchType" type="radio" value="supplier" @change="onClickSearch">
          <label for="supplier">Supplier</label>
          <input id="remaining" v-model="ui.selectedSearchType" type="radio" value="remaining" @change="onClickSearch">
          <label for="remaining">Remaining</label>
        </div>
      </div>

      <div
        v-if="compSearchInProgress && ui.searchError ===''"
        style="height: calc(63dvh - 30px); max-width: calc(100dvw - 20px)"
      >
        <com-loader style="padding-top: 100px" />
      </div>

      <div
        v-if="ui.searchResults.length === 0 && compSearchInProgress === false && ui.searchError ===''"
        style="height: calc(63dvh - 30px); max-width: calc(100dvw - 20px)"
      >
        <h1 class="text-center" style="padding-top: 120px">
          No records found
        </h1>
        <p class="text-center bold">Consider adjusting your search terms.</p>
      </div>

      <div
        v-if="ui.searchError > ''"
        style="height: calc(63dvh - 30px); max-width: calc(100dvw - 20px)"
      >
          <h1 class="text-center" style="padding-top: 120px">
            An error occurred
          </h1>
          <p class="text-center bold">{{ ui.searchError }}</p>

      </div>

      <div
        v-if="ui.searchResults.length > 0 && compSearchInProgress === false && ui.searchError ===''"
        class="new-table margin-top-half"
        style="
          height: 65dvh;
          max-height: calc(63dvh - 30px);
          max-width: calc(100dvw - 20px);
          overflow-x: scroll !important;
        "
      >
        <table style="width: calc(100%); table-layout: fixed">
          <!--                        TABLE HEADERS-->
          <thead>
            <tr style="">
              <th style="width: 12ch">Stock Code</th>
              <th>Description</th>
            </tr>
          </thead>
          <!--                        TABLE DATA -->
          <tbody>
            <tr
              v-bind:class="
                ui.selectedStkCode === item.stkcode ? 'selected' : ''
              "
              v-for="item in ui.searchResults"
              :key="item.stkcode"
              @click="onRowSelected(item.stkcode)"
            >
              <td style="width: 10ch">{{ item.stkcode }}</td>
              <td>
                <div class="autoscroll-container">
                  <div
                    v-bind:class="
                      item.description.length > 24 ? 'autoscroll-value' : ''
                    "
                  >
                    {{ item.description }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row margin-bottom-tiny">
        <div
          class="col-4 small padding-left-none padding-right-half text-unselectable"
          style="text-align: right"
        >
          Search Text
        </div>
        <div class="col-8 padding-none">
          <input
            style="margin-right: 10px"
            class="width-xlarge"
            v-model="ui.searchTerm"
            v-on:keydown.enter="onClickSearch"
          />
        </div>
      </div>

      <div class="row margin-top">
        <div class="col-5">
          <button
            v-on:click="onClickCancel"
            class="btn-default height-med"
            style="width: 100%"
          >
            Cancel
          </button>
        </div>
        <div class="col-5 offset-2">
          <button
            v-if="compHasSearchChanged"
            v-on:click="onClickSearch"
            class="btn-default btn-action height-med"
            style="width: 100%"
          >
            Search
          </button>
          <button
            v-else
            v-on:click="onClickOk"
            class="btn-default btn-action height-med"
            style="width: 100%"
            v-bind:disabled="ui.selectedStkCode === ''"
          >
            Select
          </button>
        </div>
      </div>

    </div>
  </com-modal-body>
</template>

<script setup>
import { reactive, watch, onMounted, onUnmounted, computed } from "vue";
import { globalEmitter } from "@/hooks/useGlobalEmitter";
import ComModalBody from "@/components/ui/ComModalBody.vue";
import ComLoader from "@/components/loaders/ComSearchLoader.vue";
import { pasRequest } from "@/hooks/usePas.js";
import { logDev } from "@/hooks/useEnvironment.js";

const props = defineProps({
  onSuccess: {
    type: Function,
    required: true,
  },
  onFail: {
    type: Function,
    required: true,
  },
  supplierNumber: {
    type: Number,
    required: true,
  },
  suppliersDeliveryReference: {
    type: String,
    required: true,
  },
  poNumber: {
    type: Number,
    required: true,
  }
});

const uiDefaults = {
  searchResults: [],
  searchError: "",
  selectedSearchType: "expected",
  selectedStkCode: "",
  selectedRow: null,
  searchTerm: "",
  showModal: false,
  searchingStatus: {
    minTimeoutComplete: true,
    resultBackFromServer: true,
  },
  lastSearch: {
    term: null,
    searchType: null
  },
};
const ui = reactive(Object.assign({}, uiDefaults));

const onClickCancel = function () {
  props.onFail();
};

/**
 * @desc Determines if we should be showing either the search button of the select button
 *        that chooses the item
 * @type {ComputedRef<boolean|undefined>}
 */
const compHasSearchChanged = computed(() => {

  if (ui.lastSearch.term !== ui.searchTerm) return true;

  if (ui.lastSearch.searchType !== ui.selectedSearchType) return true;

  return false;
});

const compSearchInProgress = computed(() => {
  if (ui.searchingStatus.minTimeoutComplete === false) return true;
  if (ui.searchingStatus.resultBackFromServer === false) return true;

  return false;
});

const onClickOk = function () {
  if (ui.selectedStkCode.length !== 0) {
    props.onSuccess(ui.selectedStkCode);
  }

  props.onFail();
};

// Watch the selected search method and clear the search term if it exists
watch(
  () => ui.selectedSearchType,
  () => {
    ui.searchTerm = null;
  }
);

function onRowSelected(stkcode) {
  console.dir(stkcode);

  ui.selectedStkCode = stkcode;
}

async function onClickSearch() {
  ui.searchingStatus.resultBackFromServer = false;
  ui.searchingStatus.minTimeoutComplete = false;
  ui.searchError = "";

  setTimeout(() => (ui.searchingStatus.minTimeoutComplete = true), 1000);

  ui.lastSearch = {
    term: ui.searchTerm,
    searchType: ui.selectedSearchType
  };

  ui.searchResults.length = 0;

  //Send the request to ho
  try {
    let result = await pasRequest("goods-in", "stkcode-lookup", {
      poNumber: props.poNumber,
      supplierNumber: props.supplierNumber,
      suppliersDeliveryReference: props.suppliersDeliveryReference,
      searchText: ui.searchTerm,
      searchType: ui.selectedSearchType,
      useLoader: false,
    })

    ui.searchingStatus.resultBackFromServer = true;
    ui.searchResults                        = result.results ?? [];
  } catch (e) {

    ui.searchingStatus.resultBackFromServer = true;

    if (e === null) return;

    logDev(e);

    ui.searchResults = [];
    ui.searchError   = e[0] ?? "";
  }
}

function ignoreScan() {
  console.log("Ignoring barcode scan");
}

onMounted(function () {
  //Don't want the prog underneath reacting to scans
  globalEmitter.on("barcodeScannedLevel2", ignoreScan);
  onClickSearch();
});

onUnmounted(function () {
  //Release the listener
  globalEmitter.off("barcodeScannedLevel2", ignoreScan);
});
</script>

<style scoped>
.col-4 {
  line-height: 30px;
}

input[type=radio] {
  width:30px;
}

.autoscroll-container {
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 95%;
  height: 28px;
  display: inline-flex;
  align-items: center;
  /* WebkitBoxAlign: center; */
  white-space: nowrap;
  /* // outline: 0, */
  text-decoration: none;
  /* // border: 0, */
  vertical-align: middle;
  /* // boxSizing: 'border-box', */
  overflow: hidden;
}

.autoscroll-value {
  display: inline-block;
  position: relative;
  text-overflow: clip;
}

.autoscroll-value:hover {
  animation: scroll-rtl 15s linear forwards;
}

@keyframes scroll-rtl {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-60%);
  }
  50% {
    transform: translate(0);
  }
}
</style>
