import axios from "axios";
import { showAlert } from "@/hooks/useModals.js";
import {
  showLoader,
  hideLoader,
  showMultipleStkcodeSelection,
} from "@/hooks/useUi.js";
import { sessionData } from "@/store/sessionStore.js";
import { clearLoginSession, personnelSignOut } from "@/hooks/usePersonnel.js";
import { globalEmitter } from "@/hooks/useGlobalEmitter.js";
import { hashData } from "@/hooks/useBrowserFunctions.js";
import { isDev, logDev } from "./useEnvironment";
import uiStorage from "@/store/uiStore.js";
import { deviceData } from "@/store/deviceStore.js";

export let pasBaseUrl = "";
let pasActionPath = "/web/WinRetailApi/v1.0/Pda";
export let pasStaticPath = "/static";

export function updatePasEndpoint() {
  pasBaseUrl = `http${deviceData.useHttps ? "s" : ""}://${deviceData.pasHost}${
    deviceData.pasPort === 0 ? "" : ":" + deviceData.pasPort
  }`;
}

/**
 * @desc Sends request to the Server
 * @param program {string} The name of the HO pas program that the action is contained in
 * @param action {string} The name of the action to be performed
 * @param request {object} The request object to be sent to the server
 * @return {Promise<any|null>}
 * @param useLoader {boolean} Whether to show the loader or not
 * @param silentErrors {boolean} Whether to show errors or not
 * @return {Promise<any|null>}
 */
export async function pasRequest(
  program,
  action,
  request,
  useLoader = true,
  silentErrors = false
) {
  let sessionGuid = null;
  let requestHash = hashData(JSON.stringify({ action, request }));
  let deviceGuid = null;

  if (useLoader === true) showLoader();

  return new Promise(async (success, error) => {
    //Add the device guid
    deviceGuid = deviceData?.deviceGuid;

    const allowedNoSessionActions = [
      "personnel-sign-in",
      "activate-pda-device",
      "get-company-logo",
    ];

    // Append the session GUID for all requests except those listed above
    if (allowedNoSessionActions.includes(action) === false) {
      //Shouldn't happen if initialisation checks worked on app
      if (sessionData?.sessionGuid === null) {
        hideLoader();
        await showAlert({
          title: "Session Data Missing",
          content: "Please sign in to continue",
        });
        await globalEmitter.emit("appGoTo", "Home");
        error(null);
      }

      sessionGuid = sessionData.sessionGuid;
    }

    let headerConfig = {
      headers: {
        "Content-Type": "application/json",
        "X-CF-Options": JSON.stringify({
          sessionGuid,
          requestHash,
          deviceGuid,
        }),
      },
    };

    try {
      let response = await axios.post(
        `${pasBaseUrl + pasActionPath}/${program}/${action}`,
        request,
        headerConfig
      );

      if (isDev()) {
        console.dir("------- DEV ONLY - PAS Response Start -------");
        console.dir(response);
        console.dir("------- DEV ONLY - PAS Response End -------");
      }

      hideLoader();

      switch (response.data.status.toLowerCase()) {
        //DH - REMOVED INVALID SESSION AS THIS NOW THROWS A 401 which is now handled in the catch section

        case "multi":
          uiStorage.multipleStkcodeSelect.stkmstList =
            response.data.response.stkcodes ?? [];

          try {
            let stkcode = await showMultipleStkcodeSelection();
            error({ type: "selectStkcode", stkcode });
          } catch {
            //Didn't select a stkcode, send back null which should do nothing in calling program
            error(null);
          }

          break;
        //Warnings are dealt with at the calling program level, so just resolve promise as an error with the
        // error details array attached
        case "warn":
          error(response.data.errorDetails ?? []);
          return;

        //If the progress end returns error we had to back out of a DB transaction or something bad, show a
        //critical error message and error back with null to the calling program
        case "error":
          await showAlert({
            title: "PAS Error",
            danger: true,
            content:
              "A critical error has occurred with your request, please try again or contact support if this persists",
          });
          error(null);
          return;
        case "ok":
          success(response.data.response);
      }
    } catch (e) {
      //Log the error out to the console, this isn't visible on mobile browsers without USB debugging, but we can see it on desktop when replicating
      logDev(e);
      hideLoader();

      //NEEDS TO BE CHECKED FIRST AS STATUS PROPERTY NOT ON NETWORK/TIMEOUT ERRORS
      if (e?.code === "ERR_NETWORK") {
        //If it's a network error, tell them to check their connection (it may have dropped)
        if (silentErrors === false) {
          await showAlert({
            title: "Network Error",
            content:
              "Could not reach WinRetail. Please check your connection and try again.",
          });
          error(null);
          return;
        }
        //return detail if we're handling or ignoring errors ourselves (silent)
        error("network");
      }

      //Device is not activated, don't show error, go straight to activation screen
      if (
        e.response.status === 403 &&
        e.response.data === "Missing or Invalid Device GUID"
      ) {
        logDev("Device not activated");
        await globalEmitter.emit("appGoTo", "Device Initialisation");
        error(null);
        return;
      }

      //Session expired, clear any saved session details as we know they're expired and go to login
      if (
        e.response.status === 401 &&
        e.response.data === "Missing or Expired Session GUID"
      ) {
        logDev("Invalid Session");
        await clearLoginSession();
        await globalEmitter.emit("appGoTo", "Home");
        error(null);
        return;
      }

      // If it's a 500 error, it's very likely to be a PAS/Progress error
      if (e.response.status === 500) {
        if (silentErrors === false) {
          await showAlert({
            title: "PAS Error",
            content:
              "A problem occurred processing the request at Head Office, please contact support.",
          });
          error(null);
          return;
        }

        error("pas 500");
      }

      if (silentErrors === false) {
        await showAlert(
          "Unable to contact the server, check your network connection and try again"
        );
        error(null);
        return;
      }

      error("unknown (end of catch block)");
    }
  });
}

export async function testNewPasEndpoint(baseUrl) {
  return new Promise(async (resolve, reject) => {
    try {
      const testUrl = `${baseUrl + pasActionPath}/core-api/check-version`;
      const result = await axios.post(testUrl);

      if (result.status === 200) resolve();
      else reject();
    } catch (e) {
      reject();
    }
  });
}

export async function pasFileUpload(
  files,
  useLoader = true,
  silentErrors = false
) {
  let sessionGuid = null;
  let requestHash = hashData(JSON.stringify({ files }));
  let deviceGuid = null;

  if (useLoader === true) showLoader();

  return new Promise(async (success, error) => {
    // Add the device guid
    deviceGuid = deviceData?.deviceGuid;

    // All file upload requests must be signed in
    if (sessionData.sessionGuid === null) {
      hideLoader();
      await showAlert({
        title: "Session Data Missing",
        content: "Please sign in to continue",
      });
      await globalEmitter.emit("appGoTo", "Home");
      error(null);
    }

    sessionGuid = sessionData.sessionGuid;

    if (files === null) {
      hideLoader();
      await showAlert({
        title: "File Data Missing",
        content: "No files were specified",
      });
      error(null);
      return;
    }

    if (files.length === 0) {
      hideLoader();
      await showAlert({
        title: "File Data Missing",
        content: "Please specify at least one file",
      });
      error(null);
      return;
    }

    let headerConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-CF-Options": JSON.stringify({
          sessionGuid,
          requestHash,
          deviceGuid,
        }),
      },
    };

    // Create a new FormData
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      console.log(`Attaching file: ${files[i].name}`);
      formData.append(`file-${i}`, files[i]);
    }

    try {
      let response = await axios.post(
        `${pasBaseUrl + pasActionPath}`,
        formData,
        headerConfig
      );

      if (isDev()) {
        console.dir("------- DEV ONLY - PAS Response Start -------");
        console.dir(response);
        console.dir("------- DEV ONLY - PAS Response End -------");
      }

      hideLoader();

      let numberOfFiles = 0;

      // Check the response looks valid
      numberOfFiles = response.data.files?.length;

      console.log(`${numberOfFiles} file(s) uploaded successfully`);

      // Check the response looks valid
      if (numberOfFiles !== files.length) {
        await showAlert({
          title: "PAS Error",
          danger: true,
          content:
            "A unknown error has occurred processing your request, please try again or contact support if this persists",
        });
        error(null);
        return;
      }

      success(response.data.files);
    } catch (e) {
      //Log the error out to the console, this isn't visible on mobile browsers without USB debugging, but we can see it on desktop when replicating
      logDev(e);
      hideLoader();

      //NEEDS TO BE CHECKED FIRST AS STATUS PROPERTY NOT ON NETWORK/TIMEOUT ERRORS
      if (e?.code === "ERR_NETWORK") {
        //If it's a network error, tell them to check their connection (it may have dropped)
        if (silentErrors === false) {
          await showAlert({
            title: "Network Error",
            content:
              "Could not reach WinRetail. Please check your connection and try again.",
          });
          error(null);
          return;
        }
        //return detail if we're handling or ignoring errors ourselves (silent)
        error("network");
      }

      //Device is not activated, don't show error, go straight to activation screen
      if (
        e.response.status === 403 &&
        e.response.data === "Missing or Invalid Device GUID"
      ) {
        logDev("Device not activated");
        await globalEmitter.emit("appGoTo", "Device Initialisation");
        error(null);
        return;
      }

      //Session expired, clear any saved session details as we know they're expired and go to login
      if (
        e.response.status === 401 &&
        e.response.data === "Missing or Expired Session GUID"
      ) {
        logDev("Invalid Session");
        await clearLoginSession();
        await globalEmitter.emit("appGoTo", "Home");
        error(null);
        return;
      }

      // If it's a 400 error, it indicates an issue with the upload request/PAS upload settings
      if (e.response.status === 400) {
        if (silentErrors === false) {
          await showAlert({
            title: "PAS Error",
            content: `${e.response.data}`,
          });
          error(null);
          return;
        }

        error("pas 400");
      }

      if (silentErrors === false) {
        await showAlert(
          "Unable to contact the server, check your network connection and try again"
        );
        error(null);
        return;
      }

      // If it's a 500 error, it's very likely to be a PAS/Progress error
      if (e.response.status === 500) {
        if (silentErrors === false) {
          await showAlert({
            title: "PAS Error",
            content:
              "A problem occurred processing the request at Head Office, please contact support.",
          });
          error(null);
          return;
        }

        error("pas 500");
      }

      if (silentErrors === false) {
        await showAlert(
          "Unable to contact the server, check your network connection and try again"
        );
        error(null);
        return;
      }

      error("unknown (end of catch block)");
    }
  });
}

export function imageUploadPreview(
  files,
  targetId,
  placeHolder = "src/assets/img/camera.png" // Might need to check this
) {
  if (FileReader && files && files.length) {
    let fr = new FileReader();
    fr.onload = function () {
      document.getElementById(targetId).src = fr.result;
    };
    fr.readAsDataURL(files[0]);
  } else {
    document.getElementById(targetId).src = placeHolder;
  }
}
