<template>
  <com-modal-body
    v-on:click="onClickNotificationBody"
    position="top"
    class="text-center"
    v-bind:use-mask="false"
  >
    <p
      class="margin-top"
      style="color: var(--pda-notification-color)"
      v-bind:class="
        ModalStore.notification.smallText ? 'font-small' : 'font-med'
      "
    >
      {{ ModalStore.notification.content }}
    </p>
  </com-modal-body>
</template>

<script setup>
import ComModalBody from "@/components/ui/ComModalBody.vue";
import { ModalStore } from "@/store/modalStore.js";
import { onMounted } from "vue";
import { playSound } from "@/hooks/useAudio.js";

let countdownTimer = null;
const timerLength = ModalStore.notification.timeout * 1000;

function onClickNotificationBody() {
  clearTimeout(countdownTimer);
  ModalStore.notification.closeCallback();
}

onMounted(async () => {
  countdownTimer = setTimeout(() => {
    ModalStore.notification.closeCallback();
  }, timerLength);

  if (ModalStore.notification.sound !== null)
    await playSound(ModalStore.notification.sound);
});
</script>

<style scoped></style>
