<!--suppress HtmlRequiredAltAttribute -->
<template>
  <div style="background-color: white; overflow-y: hidden">
    <!--    All Modals that are shared across multiple screens-->
    <shared-modals />

    <com-settings-pages v-if="uiStore.settingsPage.show !== ''" />

    <div v-if="ui.hideTopNav === false" class="navbar-pda">
      <div class="container">
        <div class="row text-unselectable">
          <div class="col-2 padding-left-none">
            <img
              class="margin-left-half left cf-logo"
              v-bind:src="cornerLogo"
              v-on:click="onClickLogo"
            />
          </div>
          <div class="col-7 padding-left-none">
            <div style="text-align: left; overflow-x: hidden; max-height: 100%">
              <span v-if="sessionData.personnel.displayName !== ''">
                {{ sessionData.personnel.displayName }}</span
              >
              <span v-else>No user logged in</span>

              <br />
              {{ ui.statusMessage }}
            </div>
          </div>
          <div class="col-3">
            <p style="color: gray" class="padding-none margin-bottom-none">
              {{ ui.clock }}
            </p>
            <button
              v-if="deviceData.cameraScanningEnabled === true"
              class="btn-small margin-top-none margin-top-none"
              style="height: 20px; line-height: 15px"
              v-on:click="onClickScanButton"
            >
              SCAN
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="app-pda app-router">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, onUnmounted, reactive } from "vue";
import {
  sessionStatus,
  checkEnvironment,
  deviceData,
} from "./store/deviceStore.js";
import dayjs from "dayjs";
import UAParser from "ua-parser-js";
import onScan from "onscan.js";
import cornerLogo from "./assets/img/corner-logo.jpg";
import { globalEmitter } from "./hooks/useGlobalEmitter.js";
import { sessionData } from "./store/sessionStore.js";
import { hideLoader, showLoader } from "@/hooks/useUi.js";
import { initialiseStorage } from "@/hooks/useLocalStorage.js";
import { pasRequest } from "@/hooks/usePas.js";
import useRouterNavigation from "@/hooks/useRouterNavigation.js";
import SharedModals from "@/components/ComSharedModals.vue";
import { isDev, logDev } from "@/hooks/useEnvironment.js";
import { showAlert, showConfirm } from "@/hooks/useModals.js";
import uiStore from "@/store/uiStore.js";
import ComSettingsPages from "@/components/ComSettingsPages.vue";

const { goToPage, getPageName } = useRouterNavigation();

let clockTick;

const ui = reactive({
  clock: dayjs().format("HH:mm:ss"),
  statusMessage: "PDA Home",
  hideTopNav: false,
});

function onClickLogo() {
  console.log(getPageName());

  //Allow from dev builds anywhere, only allow from main menu on prod.
  // if (isDev() || getPageName() === "Main Menu")
  uiStore.sideBar.visible = !uiStore.sideBar.visible;
}

function onClickScanButton() {
  uiStore.scannerModal.visible = true;
}

function onPageChange(page) {
  ui.statusMessage = page;
}

function onChangeRouterTarget(target) {
  logDev(`App received route change request for: ${target}`);
  goToPage(target);
}

function onBarcodeScanned(sCode, qty = 1) {
  console.log("barcode Scanned: " + sCode);

  if (uiStore.loader.enabled) {
    console.log("screen loader is open, ignoring barcode scanned");
    return;
  }

  if (uiStore.messages.length > 0) {
    console.log("alert is open, ignoring barcode scanned");
    return;
  }

  // Sometimes there is a space within a barcode.
  if (typeof sCode === "string") sCode = sCode.trim();

  // Level 3 overrides anything after it
  if (globalEmitter.listenerCount("barcodeScannedLevel3") > 0) {
    console.log("Someone is listening to barcodeScannedLevel3: " + sCode);
    globalEmitter.emit("barcodeScannedLevel3", sCode);
    return;
  }

  // Level 2 overrides anything after it
  if (globalEmitter.listenerCount("barcodeScannedLevel2") > 0) {
    console.log("Someone is listening to barcodeScannedLevel2: " + sCode);
    globalEmitter.emit("barcodeScannedLevel2", sCode);
    return;
  }

  globalEmitter.emit("barcodeScanned", sCode);
}

async function initialiseSession() {
  //Did we have a session GUID still saved? Check it's still active
  let breakLoop = false;

  while (breakLoop === false) {
    if (sessionData.sessionGuid !== null) {
      try {
        //We don't need the data from the call, just check we resolve the promise with a success

        console.log("checkSession");
        await pasRequest("core-api", "check-session-guid", {}, false, true);

        //If session was not valid we'd have skipped to the catch block instead of getting here
        goToPage("Main Menu");
        breakLoop = true;
      } catch (e) {
        console.log("E");
        console.log(e);

        if (e === "network") {
          try {
            await showConfirm({
              title: "Cannot reach WinRetail",
              content:
                "Please check your network connection or contact support if this issue persists.",
              yesLabel: "Retry",
              noLabel: "Settings",
              danger: true,
            });

            showLoader("Please Wait...", "Retrying connection to WinRetail");
            continue;
          } catch {
            breakLoop = true;
            goToPage("devTest");
            break;
          }
        }
      }
      //Null if we handle the error in the pas request hook
      // if (e === null) return;

      goToPage("Home");
    } else {
      goToPage("Home");
      breakLoop = true;
    }
  }
}
onBeforeMount(() => console.log("Version: " + __APP_VERSION__));

onMounted(async () => {
  showLoader("Please Wait...", "Initialising app");

  //Load whatever we have in local storage
  initialiseStorage();

  checkEnvironment();

  globalEmitter.on("pageChanged", onPageChange);
  globalEmitter.on("appGoTo", onChangeRouterTarget);
  globalEmitter.on("cameraBarcode", onBarcodeScanned);

  console.log(window.name);

  //DEV DISABLED
  // if (
  //   sessionStatus.desktopEnvironment === true &&
  //   window.name !== "PDA DESKTOP"
  // ) {
  //   window.open(
  //     window.location.href,
  //     "PDA DESKTOP",
  //     "width=320,height=550,resizable=no"
  //   );
  //   ui.hideTopNav = true;
  //   hideLoader();
  //   goToPage("PDA Desktop Launch");
  //   return;
  // }
  //
  // if (sessionStatus.desktopEnvironment === true) {
  //   window.addEventListener("resize", () => {
  //     window.resizeTo(320, 550);
  //   });
  // }

  await initialiseSession();

  hideLoader();
  deviceData.userAgent = UAParser();

  // if (checkPwa() === true)
  //     window.alert('PWA detected')
  // else
  //     window.alert('No PWA')

  clockTick = setInterval(() => {
    ui.clock = dayjs().format("HH:mm:ss");
  }, 1000);

  //console.log(document)
  //console.log(onScan)

  // Listen to ALL key press events
  onScan.attachTo(document, {
    prefixKeyCodes: [219],
    suffixKeyCodes: [221],
    minLength: 5,
    reactToPaste: false,
    preventDefault: true,
    ignoreIfFocusOn: ["INPUT", "TEXTAREA"],
    onScan: onBarcodeScanned,
  });

  if (deviceData.deviceLabel === "") {
    goToPage("Device Initialisation");
  }
});

onUnmounted(() => {
  clearInterval(clockTick);
  globalEmitter.off("pageChanged", onPageChange);
  globalEmitter.off("appGoTo", onChangeRouterTarget);
  globalEmitter.off("cameraBarcode", onBarcodeScanned);
  onScan.detachFrom(document);
});
</script>

<style>
.app-pda {
  text-align: center;
  overflow: hidden;
  background-color: var(--pda-background-color);
}

.navbar-pda {
  background-color: var(--pda-primary-color) !important;
  color: #bbbbbb;
  text-align: center;
  margin-left: 0;
  height: 50px;
  max-height: 50px;
  border-bottom: 1px solid black;
}

.app-router {
  margin-top: 0;
  height: calc(100dvh - 50px) !important;
  width: 100dvw;
  min-height: calc(100dvh - 50px);
}

.cf-logo {
  height: 40px;
  width: 40px;
  margin-left: 5px;
  margin-top: 5px;
  border: 1px solid black;
}

h1 {
  font-size: 2rem !important;
}

.mask {
  position: fixed;
  overflow: hidden;
  z-index: 5000;
  background-color: black;
  opacity: 0.6;
  margin-left: calc(50vw * -1);
  margin-top: 0px;
  width: 200vw;
  height: 100vh;
  animation: fadeInFromNone 0.5s ease-out;
}
</style>
