<template>
  <com-modal-body>
    <h1
      class="text-center text-unselectable"
      v-bind:class="{ 'text-danger': ModalStore.confirm.danger }"
    >
      {{ ModalStore.confirm.title }}
    </h1>
    <hr />
    <h4
      class="text-center text-unselectable"
      style="min-height: 150px"
      v-html="ModalStore.confirm.content"
    ></h4>
    <div class="row">
      <div class="col-5">
        <button
          v-on:click="onClickNo"
          class="btn-default height-med text-unselectable"
          style="width: 100%"
        >
          {{ ModalStore.confirm.noLabel }}
        </button>
      </div>
      <div class="col-5 offset-2">
        <button
          v-on:click="onClickYes"
          class="btn-default btn-action height-med text-unselectable"
          style="width: 100%"
        >
          {{ ModalStore.confirm.yesLabel }}
        </button>
      </div>
    </div>
  </com-modal-body>
</template>

<script setup>
import { ModalStore } from "@/store/modalStore.js";
import ComModalBody from "@/components/ui/ComModalBody.vue";
import { onMounted } from "vue";
import { playSound } from "@/hooks/useAudio.js";

async function onClickYes() {
  ModalStore.confirm.yesCallback();
}
async function onClickNo() {
  ModalStore.confirm.noCallback();
}

onMounted(async () => {
  if (ModalStore.confirm.sound !== null)
    await playSound(ModalStore.confirm.sound);
});
</script>

<style scoped>
h1 {
  text-align: center;
  width: 100%;
}

h4 {
  text-align: center;
}
</style>
