<template>
  <div class="left-menu-container">
    <div
      v-on:click="onClickUpdatePda"
      v-if="pwaStatus.updateAvailable"
      class="alert-info margin-top-none padding-top-small"
    >
      <i class="bs-icon bi-capslock-fill" />
      &nbsp UPDATE AVAILABLE<br />
      <p class="margin-bottom-none font-xsmall">
        (Sign Out and click here to apply)
      </p>
    </div>
    <template v-for="item in UiStorage.sideBar.items" v-bind:key="item">
      <p
        v-if="item.type === 'header'"
        class="menu-header"
        v-html="item.label"
      ></p>

      <div
        v-if="item.type === 'link' && checkItemDisabled(item) === false"
        v-on:click="onClickLink(item)"
        class="menu-label margin-bottom-half"
      >
        <div class="row text-unselectable">
          <div class="col-2 padding-left-small padding-right-none">
            <i
              v-if="item.icon !== ''"
              class="bs-icon"
              v-bind:class="'bi-' + item.icon"
            />
          </div>
          <div class="col-10">
            {{ item.label }}
          </div>
        </div>
      </div>
    </template>

    <template v-if="isDev() === true">
      <div class="menu-label margin-bottom-half" @click="onClickDev">
        <div class="row text-unselectable">
          <div class="col-2 padding-left-small padding-right-none">
            <i class="bs-icon bi-clipboard2-pulse-fill" />
          </div>
          <div class="col-10">(Dev) Test</div>
        </div>
      </div>
      <div class="menu-label margin-bottom-half" @click="onClickTemplate">
        <div class="row text-unselectable">
          <div class="col-2 padding-left-small padding-right-none">
            <i class="bs-icon bi-file-earmark-fill" />
          </div>
          <div class="col-10">(Dev) Template</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, reactive } from "vue";
import UiStorage from "@/store/uiStore.js";
import { openFullscreen } from "@/hooks/useBrowserFunctions.js";
import { isDev } from "@/hooks/useEnvironment.js";
import UseRouterNavigation from "@/hooks/useRouterNavigation.js";
import UiStore from "@/store/uiStore.js";
import { personnelSignOut } from "@/hooks/usePersonnel.js";
import { pwaStatus, sessionStatus } from "@/store/deviceStore.js";
import { sessionData } from "@/store/sessionStore.js";
import { showAlert, showConfirm } from "@/hooks/useModals.js";
import { pwaInfo } from "virtual:pwa-info";
import { updatePda } from "@/main.js";
import { showLoader } from "@/hooks/useUi.js";

const { goToPage } = UseRouterNavigation();

const ui = reactive({
  items: [],
  updateAvailable: true,
});

function onClickDev() {
  goToPage("devTest");
  close();
}

function onClickTemplate() {
  goToPage("Template");
  close();
}
function onShowWinRetailSettings() {
  UiStore.settingsPage.show = "pas";
  close();
}

async function onClickUpdatePda() {
  if (sessionData.sessionGuid !== null) {
    await showAlert({
      title: "Error",
      content:
        "Update cannot be applied whilst signed in. Please Sign out and try again",
    });
    close();
    return;
  }
  close();
  showLoader("Please Wait...", "Updating PDA App");
  await updatePda();
}

async function onSignOut() {
  close();
  try {
    await showConfirm({
      title: "Are you sure?",
      content:
        "All unsaved content will be lost and you will be immediately returned to the sign in screen",
    });
  } catch {
    return;
  }
  if (sessionData.sessionGuid !== null && (await personnelSignOut()) === true) {
    goToPage("Home");
  }
}

function onClickLink(item) {
  switch (item.label) {
    case "Sign Out":
      onSignOut();
      return;
    case "Force Full Screen":
      openFullscreen();
      return;
    case "Device Details":
      onShowDeviceDetails();
      return;
    case "Error Logs":
      onShowErrorLogs();
      return;
    case "WinRetail":
      onShowWinRetailSettings();
      return;
    case "Camera":
      onshowCameraSettings();
      return;
    default:
      close();
      return;
  }
}

function onShowDeviceDetails() {
  window.alert(`Version:   ${__APP_VERSION__}`);
}

function checkItemDisabled(item) {
  switch (item.label) {
    case "Sign Out":
      return sessionData.sessionGuid === null;

    default:
      return false;
  }
}

function close() {
  UiStorage.sideBar.visible = false;
}
</script>

<style scoped>
.bs-icon {
  font-size: 1.5em;
}

.menu-header {
  padding-left: 15px;
  font-size: 22px;
  line-height: 50px;
  margin: 0;
}

.menu-label {
  padding-left: 20px;
  line-height: 50px;
  background-color: var(--btn-color);
  border-radius: 0 5px 5px 0;
  margin: 0 25px 0 0;
}

.menu-item-disabled {
  background-color: #6c6c6c;
  color: #989898;
}

.left-menu-container {
  z-index: 9999;
  position: fixed;
  top: 50px;
  right: calc(100vw - 230px);
  width: 230px;
  background-color: white;
  height: calc(100vh - 50px);
  overflow-y: scroll;
}
</style>
